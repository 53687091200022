import axios from '../utils/axiosDavo';

class FeeService {

  findAddressByClientId = clientId => axios.get(`/clients/${clientId}/addresses?type=shipping`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });
}

const feeService = new FeeService();

export default feeService;
