import React, { useReducer } from 'react';
import logUserService from 'src/services/logUserService';
import { LOG_USER_FETCH, LOG_USER_CREATE } from './LogUserActions';
import LogUserReducer from './LogUserReducer';
import LogUserContext from './LogUserContext';

const LogUserState = props => {
  const initialState = {
    logs: []
  };

  const [state, dispatch] = useReducer(LogUserReducer, initialState);

  const createLog = (ip, userId) =>
    logUserService.createLog(ip, userId).then(log => {
      dispatch({
        type: LOG_USER_CREATE,
        payload: log
      })
    });

    const fetchLogs = () => 
      logUserService.fetchLogs().then(logs => {
        dispatch({
          type: LOG_USER_FETCH,
          payload: logs
        })
      })

  return (
    <LogUserContext.Provider value={{
      logs: state.logs,

      createLog,
      fetchLogs
    }}>
      { props.children }
    </LogUserContext.Provider>
  )
};

export default LogUserState;
