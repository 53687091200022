import FeeContext from './FeeContext';
import { API_RESULT, CREATE_FEE, FEES_CHANGE } from './FeeActions';
import React, { useReducer, useEffect } from 'react';
import FeeReducer from './FeeReducer';
import feeService from '../../services/feeService';

const FeeState = props => {
  const initialState = {
    fees: [],
    apiResponse: null
  }

  const [state, dispatch] = useReducer(FeeReducer, initialState);

  useEffect(() => {
    fetchFees();
  }, [])

  const fetchFees = async () => {
    const fees = await feeService.fetchFees();

    dispatch({
      type: FEES_CHANGE,
      payload: fees ? fees : []
    })
  }

  const createFee = async fee => {
    const newFee = await feeService.createFee(fee);

    if (!newFee) {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'post',
          type: 'error',
          message: 'No se ha podido crear la tarifa'
        }
      })
    } else {
      dispatch({
        type: CREATE_FEE,
        payload: newFee
      })
    }
  }

  const resetApiResult = () => {
    dispatch({
      type: API_RESULT,
      payload: null
    })
  }

  return (
    <FeeContext.Provider value={{
      fees: state.fees,
      apiResult: state.apiResult,
      createFee,
      resetApiResult
    }}>
      { props.children }
    </FeeContext.Provider>
  )
}

export default FeeState;
