import axios from '../utils/axiosDavo';

class AnalyticsService {

  fetchClientAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/clients?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  fetchCommercialAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/commercials?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data[0] : [])
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  fetchFeeAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/fees?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data[0] : [])
      .catch(error => {
        console.error(error);
        return [];
      });
  }

  fetchProductAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/products?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data[0] : [])
      .catch(error => {
        console.error(error);
        return [];
      });
  }

  fetchOptionAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/options?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data : [])
      .catch(error => {
        console.error(error);
        return [];
      });
  }

  fetchTotalAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/totals?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data[0] : [])
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  fetchTotalResumeAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/resume_totals?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data[0] : [])
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  fetchUserAnalytics(initDate, endDate, clientType) {
    return axios.get(`/analytics/users?init_date=${initDate}&end_date=${endDate}&client_type=${clientType}`)
      .then(response => response.data.length > 0 ? response.data[0] : [])
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

}

const analyticsService = new AnalyticsService();
export default analyticsService;
