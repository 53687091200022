import {
  API_RESULT,
  CREATE_VOUCHER,
  VOUCHERS_CHANGE,
  LOADING_VOUCHERS,
  CHANGE_FILTERS,
  PICK_VOUCHER, UPDATE_VOUCHER
} from './VoucherActions';
import { CLEAR_API_STATUS } from '../orders/OrderActions';

export default (state, action) => {
  switch (action.type) {
    case VOUCHERS_CHANGE:
      return {
        ...state,
        loading: false,
        vouchers: action.payload.vouchers,
        totalVouchers: action.payload.totalVouchers,
        filters: action.payload.filters
      }
    case CREATE_VOUCHER:
      return {
        ...state,
        vouchers: [...state.vouchers, action.payload],
        apiResult: {
          action: 'post',
          status: 'success',
          message: 'Bono creado correctamente'
        }
      }
    case LOADING_VOUCHERS:
      return {
        ...state,
        loading: true
      }
    case API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    case CHANGE_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case PICK_VOUCHER:
      return {
        ...state,
        voucherPicked: action.payload,
        apiResult: null
      };
    case UPDATE_VOUCHER:
      return {
        ...state,
        voucherPicked: action.payload,
        apiResult: {
          action: 'post',
          status: 'success',
          message: 'Bono canjeado correctamente'
        },
        vouchers: state.vouchers.map(voucher => voucher.id === action.payload.id ? action.payload : voucher)
      }
    case CLEAR_API_STATUS:
      return {
        ...state,
        apiResult: null
      };
    default:
      return state;
  }
}
