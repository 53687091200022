import axios from '../utils/axiosDavo';
import jwtDecode from 'jwt-decode';

class PartnerService {

  fetchPartner = () => {
    let token = localStorage.getItem('accessToken');
    if(token) {
      return axios.get(`/partners/${jwtDecode(token).partnerId}`)
        .then(response => response.data)
        .catch(error => {
          console.error(error)
          return undefined
        });
    } else {
      return undefined;
    }
  }

  updateSalesMessageBlocked = (salesMessageBlocked) => {
    let token = localStorage.getItem('accessToken');
    if(token) {
      return axios.put(`/partners/${jwtDecode(token).partnerId}/sales_message_blocked`, {salesMessageBlocked})
        .then(response => response.data)
        .catch(error => {
          console.error(error)
          return undefined
        });
    } else {
      return undefined;
    }
  }
}

const partnerService = new PartnerService();

export default partnerService;
