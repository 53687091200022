/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => localStorage.getItem('type') === 'b2b' ?  <Redirect to="/app/management/companies" /> : <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('./views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/api',
        component: lazy(() => import('./views/management/ApiIntegrationView'))
      },
      {
        exact: true,
        path: '/app/logs',
        component: lazy(() => import('./views/management/LogListView'))
      },
      // ANALYTICS
      {
        exact: true,
        path: '/app/management/reports',
        component: lazy(() => import('./views/management/DashboardView'))
      },
      // CUSTOMERS
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('./views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/companies',
        component: lazy(() => import('./views/management/CompanyListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:clientId',
        component: lazy(() => import('./views/management/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/companies/create',
        component: lazy(() => import('./views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:clientId/edit',
        component: lazy(() => import('./views/management/CustomerEditView'))
      },
      // COMMERCIALS
      {
        exact: true,
        path: '/app/management/commercials',
        component: lazy(() => import('./views/management/ComercialListView'))
      },
      {
        exact: true,
        path: '/app/management/commercials/create',
        component: lazy(() => import('./views/management/CommercialCreateView'))
      },
      {
        exact: true,
        path: '/app/management/commercials/:commercial_id',
        component: lazy(() => import('./views/management/CommercialUpdateView'))
      },
      // PRODUCTS
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('./views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('./views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/:productId',
        component: lazy(() => import('./views/management/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/management/voucher_products',
        component: lazy(() => import('./views/management/ProductVoucherListView'))
      },
      {
        exact: true,
        path: '/app/management/voucher_products/create',
        component: lazy(() => import('./views/management/ProductVoucherCreateView'))
      },
      {
        exact: true,
        path: '/app/management/voucher_products/:productId',
        component: lazy(() => import('./views/management/ProductVoucherEditView'))
      },

      // FAMILIES
      {
        exact: true,
        path: '/app/management/families',
        component: lazy(() => import('./views/management/FamilyListView'))
      },
      {
        exact: true,
        path: '/app/management/families/create',
        component: lazy(() => import('./views/management/FamilyCreateView'))
      },
      {
        exact: true,
        path: '/app/management/families/:familyId',
        component: lazy(() => import('./views/management/FamilyEditView'))
      },
      // PRICES
      {
        exact: true,
        path: '/app/management/prices',
        component: lazy(() => import('./views/management/PriceListView'))
      },
      {
        exact: true,
        path: '/app/management/prices/create',
        component: lazy(() => import('./views/management/PriceCreateView'))
      },
      {
        exact: true,
        path: '/app/management/prices/:priceId',
        component: lazy(() => import('./views/management/PriceEditView'))
      },
      // ORDERS
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('./views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('./views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders_company',
        component: lazy(() => import('./views/management/OrderCompanyListView'))
      },
      {
        exact: true,
        path: '/app/management/vouchers',
        component: lazy(() => import('./views/management/VoucherListView'))
      },
      {
        exact: true,
        path: '/app/management/vouchers/create',
        component: lazy(() => import('src/views/management/VoucherCreateView'))
      },
      {
        exact: true,
        path: '/app/management/vouchers/:voucherId',
        component: lazy(() => import('./views/management/VoucherDetailsView'))
      },
      // MARKETING Y NOTIFICACIONES
      {
        exact: true,
        path: '/app/management/notifications',
        component: lazy(() => import('./views/management/NotificationListView'))
      },
      {
        exact: true,
        path: '/app/management/notifications/create',
        component: lazy(() => import('src/views/management/NotificationCreateView'))
      },
      {
        exact: true,
        path: '/app/management/notifications/:notificationId',
        component: lazy(() => import('src/views/management/NotificationEditView'))
      },
      {
        exact: true,
        path: '/app/management/home_sections',
        component: lazy(() => import('./views/management/HomeSectionListView'))
      },
      {
        exact: true,
        path: '/app/management/home_sections/create',
        component: lazy(() => import('src/views/management/HomeSectionCreateView'))
      },
      {
        exact: true,
        path: '/app/management/home_sections/:homeSectionId',
        component: lazy(() => import('src/views/management/HomeSectionEditView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      // MÉTODOS DE ENVÍO
      {
        exact: true,
        path: '/app/management/shipping_methods',
        component: lazy(() => import('./views/management/ShippingMethodListView'))
      },
      {
        exact: true,
        path: '/app/management/shipping_methods/create',
        component: lazy(() => import('./views/management/ShippingMethodCreateView'))
      },
      {
        exact: true,
        path: '/app/management/shipping_methods/:shippingMethodId',
        component: lazy(() => import('./views/management/ShippingMethodEditView'))
      },
      // ZONAS DE ENVÍO
      {
        exact: true,
        path: '/app/management/shipping_areas',
        component: lazy(() => import('./views/management/ShippingAreaListView'))
      },
      {
        exact: true,
        path: '/app/management/shipping_areas/create',
        component: lazy(() => import('./views/management/ShippingAreaCreateView'))
      },
      {
        exact: true,
        path: '/app/management/shipping_areas/:shippingAreaId',
        component: lazy(() => import('./views/management/ShippingAreaEditView'))
      },
      // LOCALES
      {
        exact: true,
        path: '/app/management/venues',
        component: lazy(() => import('./views/management/VenueListView'))
      },
      {
        exact: true,
        path: '/app/management/venues/:venueId',
        component: lazy(() => import('./views/management/VenueEditView'))
      },
      // FIDELIZACIÓN
      {
        exact: true,
        path: '/app/management/coupons',
        component: lazy(() => import('./views/management/CouponListView'))
      },
      {
        exact: true,
        path: '/app/management/coupons/create',
        component: lazy(() => import('src/views/management/CouponCreateView'))
      },
      {
        exact: true,
        path: '/app/management/coupons/:couponId',
        component: lazy(() => import('./views/management/CouponEditView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
