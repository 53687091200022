import AnalyticsContext from './AnalyticsContext';
import React, { useReducer } from 'react';
import {
  FETCH_PRODUCT_ANALYTICS,
  FETCH_COMMERCIAL_ANALYTICS,
  FETCH_FEE_ANALYTICS,
  FETCH_CLIENT_ANALYTICS,
  FETCH_TOTAL_ANALYTICS,
  INIT_ANALYTICS, FETCH_USERS_ANALYTICS, FETCH_TOTAL_RESUME_ANALYTICS, FETCH_OPTION_ANALYTICS
} from './AnalyticsActions';
import analyticsService from '../../services/analyticsService';
import AnalyticsReducer from './AnalyticsReducer';

const AnalyticsState = props => {
  const initialState = {
    temp: null,
    clientType: null,
    clientAnalytics: null,
    commercialAnalytics: null,
    feeAnalytics: null,
    productAnalytics: null,
    totalAnalytics: null,
    totalsResumeAnalytics: null,
    userAnalytics: null
  }

  const fetchClientAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchClientAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_CLIENT_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const fetchCommercialAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchCommercialAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_COMMERCIAL_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const fetchFeeAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchFeeAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_FEE_ANALYTICS,
        payload: analyticsResponse
      });
    }
  }

  const fetchProductAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchProductAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_PRODUCT_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const fetchOptionAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchOptionAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_OPTION_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const fetchResumeTotalsAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchTotalResumeAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_TOTAL_RESUME_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const fetchTotalAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchTotalAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_TOTAL_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const fetchUserAnalytics = async (initDate, endDate, type) => {
    const analyticsResponse = await analyticsService.fetchUserAnalytics(initDate, endDate, type);

    if (analyticsResponse) {
      dispatch({
        type: FETCH_USERS_ANALYTICS,
        payload: analyticsResponse
      })
    }
  }

  const initAnalytics = (temp, type, initDate = undefined, endDate = undefined) => {
    const payload = {
      temp,
      type
    };

    dispatch({
      type: INIT_ANALYTICS,
      payload
    });

    const today = new Date();
    today.setFullYear(today.getFullYear());

    switch (temp) {
      case 'last_month':
        initDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        endDate.setDate(endDate.getDate() + 1);

        initDate = `${initDate.getFullYear()}-${("0" + (initDate.getMonth() + 1)).slice(-2)}-${("0" + initDate.getDate()).slice(-2)}`;
        endDate = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;
        break;

      case 'last_30_days':
        endDate = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${("0" + today.getDate()).slice(-2)}`;
        today.setDate(today.getDate() - 30);
        initDate = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${("0" + today.getDate()).slice(-2)}`;
        break;

      case 'last_year':
        initDate = `${today.getFullYear() - 1}-01-01`;
        endDate = `${today.getFullYear()}-01-01`;
        break;

      case 'custom':
        if (initDate !== undefined) {
          if (endDate === undefined) endDate = new Date();
          initDate = `${initDate.getFullYear()}-${("0" + (initDate.getMonth() + 1)).slice(-2)}-${("0" + initDate.getDate()).slice(-2)}`;
          endDate = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;
        }
        break;

      default:
        console.log({temp});
    }

    if (initDate && endDate) {
      fetchResumeTotalsAnalytics(initDate, endDate, type).then();
      fetchClientAnalytics(initDate, endDate, type).then();
      fetchProductAnalytics(initDate, endDate, type).then();
      fetchFeeAnalytics(initDate, endDate, type).then();
      if (type === 'company') {
        fetchCommercialAnalytics(initDate, endDate, type).then();
      }
      fetchTotalAnalytics(initDate, endDate, type).then();
      fetchUserAnalytics(initDate, endDate, type).then();
      fetchOptionAnalytics(initDate, endDate, type).then();
    }
  }

  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  return (
    <AnalyticsContext.Provider value={{
      clientAnalytics: state.clientAnalytics,
      clientType: state.clientType,
      commercialAnalytics: state.commercialAnalytics,
      feeAnalytics: state.feeAnalytics,
      productAnalytics: state.productAnalytics,
      totalAnalytics: state.totalAnalytics,
      totalsResumeAnalytics: state.totalsResumeAnalytics,
      userAnalytics: state.userAnalytics,
      optionAnalytics: state.optionAnalytics,
      temp: state.temp,

      initAnalytics
    }}>
      { props.children }
    </AnalyticsContext.Provider>
  )

}

export default AnalyticsState;
