import axios from '../utils/axiosDavo';

class PaymentMethodService {

  fetchPaymentMethods = () => axios.get(`/payment_methods`)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return []
    });
}

const paymentMethodService = new PaymentMethodService();

export default paymentMethodService;
