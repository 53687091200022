import axios from '../utils/axiosDavo';

class HomeSectionService {

  fetchHomeSections = () => axios.get(`/home_sections`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  createHomeSection = homeSection =>
    axios.post(`/home_sections`, homeSection)
      .then(response => response.data)

  updateHomeSection = (id, homeSection) =>
    axios.put(`/home_sections/${id}`, homeSection)
      .then(response => response.data)

  getHomeSection = id =>
    axios.get(`home_sections/${id}`)
      .then(response => response.data)

  deleteHomeSection = id =>
    axios.delete(`home_sections/${id}`)
      .then(response => response.data)
}

const homeSectionService = new HomeSectionService();

export default homeSectionService;
