export const CHANGE_PRODUCTS = 'CHANGE_PRODUCTS';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const PICK_PRODUCT = 'PICK_PRODUCT';
export const ADD_IMAGES_TO_PICK_PRODUCT = 'ADD_IMAGES_TO_PICK_PRODUCT'
export const PRODUCT_CHANGE = 'PRODUCT_CHANGE'
export const LAUNCH_ERROR = 'LAUNCH_ERROR'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const SHOW_DELETE_DIALOG = 'SHOW_DELETE_DIALOG'
export const DISMISS_DELETE_DIALOG = 'DISMISS_DELETE_DIALOG'
