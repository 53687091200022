import NotificationContext from './NotificationContext';
import {
  ADD_NOTIFICATION,
  CLEAR_API_RESPONSE,
  DELETE_NOTIFICATION,
  NOTIFICATIONS_CHANGE,
  PICK_NOTIFICATION,
  SEND_API_RESPONSE,
  UPDATE_NOTIFICATION
} from './NotificationActions';
import React, { useReducer } from 'react';
import NotificationReducer from './NotificationReducer';
import notificationService from '../../services/notificationService';

const NotificationState = props => {
  const initialState = {
    notifications: [],
    notificationPicked: null,
    apiResponse: null
  }

  const [state, dispatch] = useReducer(NotificationReducer, initialState);

  const fetchNotifications = async () => {
    const notifications = await notificationService.fetchNotifications();

    dispatch({
      type: NOTIFICATIONS_CHANGE,
      payload: notifications
    })
  }

  const createNotification = async notification => {
    const newNotification =
      await notificationService.createNotification(notification);

    if (newNotification) {
      dispatch({
        type: ADD_NOTIFICATION,
        payload: newNotification
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido crear la notificación'
        }
      })
    }
  }

  const clearApiResponse = () => {
    dispatch({
      type: CLEAR_API_RESPONSE
    })
  }

  const sendPushNotification = async notificationId => {
    const notificationSended = await notificationService.sendPushNotification(notificationId)

    if (notificationSended) {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'success',
          message: 'Notificación enviada correctamente'
        }
      })
    } else {
      dispatch({
        type: 'error',
        message: 'No se ha podido enviar la notificación'
      })
    }
  }

  const getNotificationById = async notificationId => {
    const notification = await notificationService.getNotification(notificationId)

    if (notification) {
      dispatch({
        type: PICK_NOTIFICATION,
        payload: notification
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido recuperar la notificación'
        }
      })
    }
  }

  const updateNotification = async (id, notification) => {
    const updatedNotification =
      await notificationService.updateNotification(id, notification);

    if (updatedNotification) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: updatedNotification
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido actualizar la notificación'
        }
      })
    }
  }

  const deleteNotification = async notificationId => {
    const deletedNotification =
      await notificationService.deleteNotification(notificationId)

    if (deletedNotification) {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: deletedNotification
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido eliminar la notificación'
        }
      })
    }
  }

  const findNotificationsByFilter = async showInHome => {
    const notifications = showInHome
      ? await notificationService.fetchShowInHomeNotifications()
      : await notificationService.fetchNotifications()

    if (notifications) {
      dispatch({
        type: NOTIFICATIONS_CHANGE,
        payload: notifications
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se han podido recuperar las notificaciones'
        }
      })
    }

  }

  return (
    <NotificationContext.Provider value={{
      apiResponse: state.apiResponse,
      notificationPicked: state.notificationPicked,
      notifications: state.notifications,
      clearApiResponse,
      createNotification,
      deleteNotification,
      fetchNotifications,
      findNotificationsByFilter,
      getNotificationById,
      sendPushNotification,
      updateNotification
    }}>
      { props.children }
    </NotificationContext.Provider>
  )
}

export default NotificationState;
