import FamilyContext from './FamilyContext';
import familyService from '../../services/familyService';
import {
  ADD_FAMILY,
  API_RESULT,
  CLEAN_API_RESULT, DELETE_FAMILY,
  FAMILIES_CHANGE, UPDATE_FAMILY
} from './FamilyActions';
import React, { useReducer, useEffect } from 'react';
import FamilyReducer from './FamilyReducer';

const FamilyState = props => {
  const initialState = {
    families: [],
    apiResult: null
  }

  const [state, dispatch] = useReducer(FamilyReducer, initialState);

  useEffect(() => {
    fetchFamilies();
  }, [])

  const fetchFamilies = async () => {
    const families = await familyService.fetchFamilies();

    dispatch({
      type: FAMILIES_CHANGE,
      payload: families
    })
  }

  const createFamily = async familyForm => {
    const newFamily = await familyService.createFamily(familyForm);

    if (newFamily) {
      dispatch({
        type: ADD_FAMILY,
        payload: newFamily
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'post',
          type: 'error',
          message: 'No se ha podido crear la categoría'
        }
      })
    }
  }

  const updateFamily = async (familyId, familyForm) => {
    const updatedFamily = await familyService.updateFamily(familyId, familyForm);

    if (updatedFamily) {
      dispatch({
        type: UPDATE_FAMILY,
        payload: updatedFamily
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'put',
          type: 'error',
          message: 'No se ha podido actualizar la categoría'
        }
      })
    }
  }

  const deleteFamily = async familyId => {
    const familyDeleted = await familyService.deleteFamily(familyId);

    if (familyDeleted) {
      dispatch({
        type: DELETE_FAMILY,
        payload: familyDeleted
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'delete',
          type: 'error',
          message: 'No se ha podido eliminar el producto'
        }
      });
    }
  };

  const cleanApiResult = () => {
    dispatch({
      type: CLEAN_API_RESULT
    })
  }

  return (
    <FamilyContext.Provider value={{
      apiResult: state.apiResult,
      families: state.families,
      cleanApiResult,
      createFamily,
      deleteFamily,
      updateFamily
    }}>
      { props.children }
    </FamilyContext.Provider>
  )
}

export default FamilyState;
