import axios from '../utils/axiosDavo';

class ShippingMethodService {

  fetchShippingMethods = () => axios.get(`/shipping_methods`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  deleteShippingMethod = shippingMethodId => axios.delete(`/shipping_methods/${shippingMethodId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return null;
    })

  createShippingMethod = (deliveryDays, erpId, freeFrom, limitHour, minTotalPrice, name, preparationDays, scope, type, value) => axios.post('/shipping_methods', {
    deliveryDays,
    erpId,
    freeFrom,
    limitHour: type !== 'take_away' ? limitHour.split(':')[0] : undefined,
    minTotalPrice,
    name,
    preparationDays,
    scope,
    type,
    value
  }).then(response => response.data).catch(error => {
    console.error(error);
    return null;
  });

  updateShippingMethod = (id, deliveryDays, erpId, freeFrom, limitHour, minTotalPrice, name, preparationDays, scope, type, value) =>
    axios.put(`/shipping_methods/${id}`, {
      deliveryDays,
      erpId,
      freeFrom,
      limitHour: limitHour.split(':')[0],
      minTotalPrice,
      name,
      preparationDays,
      scope,
      type,
      value
  }).then(response => response.data).catch(error => {
    console.error(error);
    return null;
  })

  getShippingMethod = id =>
    axios.get(`/shipping_methods/${id}`)
      .then(result => result.data)
      .catch(error => console.error(error));
}

const shippingMethodService = new ShippingMethodService();

export default shippingMethodService;
