import PaymentMethodReducer from './PaymentMethodReducer';
import React, { useEffect, useReducer } from 'react';
import PaymentMethodContext from './PaymentMethodContext';
import paymentMethodService from '../../services/paymentMethodService';
import { GET_PAYMENT_METHODS } from './PaymentMethodActions';

const PaymentMethodState = props => {
  const initialState = {
    paymentMethods: []
  };

  useEffect(() => {
    paymentMethodService.fetchPaymentMethods().then(paymentMethods => {
      dispatch({
        type: GET_PAYMENT_METHODS,
        payload: paymentMethods
      })
    })
  }, []);

  const [state, dispatch] = useReducer(PaymentMethodReducer, initialState);

  return (
    <PaymentMethodContext.Provider value={{
      paymentMethods: state.paymentMethods
    }}>
      { props.children }
    </PaymentMethodContext.Provider>
  )
};

export default PaymentMethodState;
