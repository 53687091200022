import {
  ORDERS_CHANGE,
  PICK_ORDER,
  UPDATE_ORDER,
  API_RESPONSE,
  CLEAR_API_STATUS, CLEAN_IMPORT_EXPORT_RESPONSE, IMPORT_ORDERS, CHANGE_FILTER, EXPORT_ORDERS, EXPORT_DETAILS, INIT_IMPORTS
} from './OrderActions';

export default (state, action) => {
  switch (action.type) {
    case ORDERS_CHANGE:
      return {
        ...state,
        orders: action.payload.orders,
        totalOrders: action.payload.totalOrders
      };
    case PICK_ORDER:
      return {
        ...state,
        orderPicked: action.payload,
        apiResult: null
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: [...state.orders].map(order => order.id === action.payload.id ? action.payload : order),
        orderPicked: action.payload,
        apiResult: {
          action: 'updated',
          status: 'success',
          message: 'Pedido actualizado correctamente'
        }
      };
    case API_RESPONSE:
      return {
        ...state,
        apiResult: action.payload
      };
    case CLEAR_API_STATUS:
      return {
        ...state,
        apiResult: null
      };
    case CLEAN_IMPORT_EXPORT_RESPONSE:
      return {
        ...state,
        ordersImported: null,
        ordersExported: null,
        detailsExported: null
      };
    case EXPORT_ORDERS:
      return {
        ...state,
        ordersExported: action.payload
      }
    case IMPORT_ORDERS:
      return {
        ...state,
        ordersImported: action.payload,
        loading: false
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filters: action.payload
      };
    case EXPORT_DETAILS:
      return {
        ...state,
        detailsExported: action.payload
      };
      case INIT_IMPORTS:
        return {
          ...state,
          loading: true
        };
    default:
      return state;
  }
}
