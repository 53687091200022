import axios from '../utils/axiosDavo';

class ClientService {
  constructor() {
    this._limit = 20;
  }

  createClient = (clientForm) =>
    axios.post('/clients', {...clientForm, type: 'company', from: 'panel'})
      .then(response => response)
      .catch(error => {
        console.error(error);
        return undefined;
    });

  fetchClientsByFilters = (query, type, page, limit = this._limit) =>
    axios.get(`/clients?search_text=${query}&offset=${this._limit * page}&limit=${limit}&type=${type}`)
      .then(response => response)
      .catch(error => {
        console.error(error)
        return []
      });

  fetchClientsByCommercialAndFilters = (commercialId, query, type, page, limit = this._limit) =>
    axios.get(`commercials/${commercialId}/clients?search_text=${query}&offset=${this._limit * page}&limit=${limit}&type=${type}`)
      .then(response => response)
      .catch(error => {
        console.error(error)
        return []
      });

  findClientById = clientId =>
    axios.get(`/clients/${clientId}`)
      .then(response => response.data)
      .catch(error => {
        console.error(error)
        return null
      });

  importClient = ({ erpId, name, email, phone, street, city, postalCode, company, nif, type}) => {
    const importClient = {
      erpId,
      name,
      email,
      phone,
      street,
      city,
      postalCode,
      company,
      nif,
      type,
      from: 'csv'
    }

    return axios.post(`/clients`, importClient);
  }

  deleteClient = clientId => {
    return axios.delete(`/clients/${clientId}`);
  }

  updateClient = (clientForm, clientId) => {
    return axios.put(`clients/${clientId}`, clientForm)
      .then(result => result)
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  updateClientStatus = (clientId, clientStatus) =>
    axios.put(`/clients/${clientId}`, {status: clientStatus})
      .then(result => result.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  updateClientHidden = (clientId, hidden) =>
    axios.put(`/clients/${clientId}`, {hidden})
      .then(result => result.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  updateClientCommercial = (clientId, commercialId) =>
    axios.put(`/clients/${clientId}`, {commercialId})
      .then(result => result.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  updateClientFee = (clientId, feeId) =>
    axios.put(`/clients/${clientId}`, {feeId})
      .then(result => result.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  updateClientPaymentMethod = (clientId, paymentMethodId) =>
    axios.put(`/clients/${clientId}`, {paymentMethodId})
      .then(result => result.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  updateClientShippingMethod = (clientId, shippingMethodId) =>
    axios.put(`/clients/${clientId}`, {shippingMethodId})
      .then(result => result.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  sendClientEmail = (clientId, type) => axios.post(`clients/${clientId}/emails?type=${type}`);
}

const clientService = new ClientService();

export default clientService;
