export const CHANGE_PRICES = 'CHANGE_PRICES';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const PICK_PRICE = 'PICK_PRICE';
export const UPSERT_PRICE = 'UPSERT_PRICE';
export const LAUNCH_ERROR = 'LAUNCH_ERROR'
export const CLEAR_PRICES = 'CLEAR_PRICES'
export const DELETE_PRICE = 'DELETE_PRICE'
export const SHOW_DELETE_DIALOG = 'SHOW_DELETE_DIALOG'
export const DISMISS_DELETE_DIALOG = 'DISMISS_DELETE_DIALOG'
export const PICK_PRODUCT = 'PICK_PRODUCT'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const DELETE_ALL_PRICES_BY_PRODUCT = 'DELETE_ALL_PRICES_BY_PRODUCT'
export const EXPORT_PRICES_RESPONSE = 'EXPORT_PRICES_RESPONSE'
export const IMPORT_PRICES_RESPONSE = 'IMPORT_PRICES_RESPONSE'
export const CLEAR_IMPORT_EXPORT_RESPONSE = 'CLEAR_IMPORT_RESPONSE'
export const INIT_IMPORTS = 'INIT_IMPORTS';
