import axios from '../utils/axiosDavo';

class CommercialService {

  createCommercial = (commercialForm) => axios.post(`/commercials`, commercialForm).then(response => response.data).catch(console.error);

  getCommercialById = (commercialId) => axios.get(`/commercials/${commercialId}`).then(response => response.data).catch(console.error);

  getCommercials = (offset, searchText) => axios.get(`/commercials?search_text=${searchText ?? ''}&offset=${offset ?? 0}&limit=${60}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return [];
    });

    updateCommercial = (commercialId, commercialForm) => axios.put(`/commercials/${commercialId}`, commercialForm).then(response => response.data).catch(console.error);
}

const commercialService = new CommercialService();

export default commercialService;
