import { API_RESULT, FETCH_VENUES, UPDATE_VENUE } from './VenueActions';

export default (state, action) => {
  switch (action.type) {
    case API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    case FETCH_VENUES:
      return {
        ...state,
        venues: action.payload
      }
    case UPDATE_VENUE:
      return {
        ...state,
        venues: [...state.venues].map(venue => venue.id === action.payload.id ? action.payload : venue),
        apiResult: {
          type: 'success',
          message: 'Local actualizado correctamente'
        }
      }
    default:
      return state;
  }
}
