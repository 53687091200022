import React, { useReducer } from 'react';
import ProductReducer from './ProductReducer';
import ProductContext from './ProductContext';
import productService from '../../services/productService';
import {
  ADD_IMAGES_TO_PICK_PRODUCT, CHANGE_FEATURES,
  CHANGE_FILTER,
  CHANGE_PRODUCTS,
  CLEAR_IMPORT_EXPORT_RESPONSE,
  CLEAR_PRODUCTS,
  DELETE_PRODUCT,
  DISMISS_DELETE_DIALOG, EDIT_FEATURE,
  EXPORT_PRODUCTS_RESPONSE,
  IMPORT_PRODUCTS_RESPONSE,
  INIT_IMPORTS,
  LAUNCH_ERROR,
  PICK_PRODUCT,
  PRODUCT_CHANGE,
  SHOW_DELETE_DIALOG
} from './ProductActions';
import featureService from '../../services/featureService';

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
function _groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

const ProductState = props => {
  const initialState = {
    products: [],
    totalProducts: 0,
    loading: false,
    productPicked: null,
    productChanged: null,
    error: null,
    filters: {
      category: 'all',
      outStock: false,
      query: null,
      page: 0
    },
    showDelete: false,
    productsImported: null,
    productsExported: null,
    features: [],
    featuresGroups: [],
    featureGroupSelected: undefined,
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  const filtersChange = async filter => {
    dispatch({
      type: CHANGE_FILTER,
      payload: filter
    });

    const { category, page, query, outStock } = filter;

    const productResponse = category !== 'all'
      ? await productService.fetchProductsByFamily(category, page, query, outStock ? 0 : undefined)
      : await productService.fetchProducts(page, query, outStock ? 0 : undefined);

    dispatch({
      type: CHANGE_PRODUCTS,
      payload: {
        products: productResponse.data ? productResponse.data : [],
        totalProducts: productResponse.headers
          ? parseInt(productResponse.headers['x-total-count'])
          : productResponse.data.length
      }
    });
  };

  const pickProduct = async productId => {
    const product = await productService.findProductById(productId);

    if (product) {
      dispatch({
        type: PICK_PRODUCT,
        payload: product
      });
    }

  };

  const addImagesToPickedProduct = newImages => {
    dispatch({
      type: ADD_IMAGES_TO_PICK_PRODUCT,
      payload: newImages
    });
  };

  const initProductFeatures = async productId => {
    const features = await featureService.findFeaturesByProductIdAsync(productId);
    const groups = new Set();
    features.forEach(feature => {
      groups.add(feature.group);
    });

    dispatch({
      type: CHANGE_FEATURES,
      payload: {
        features,
        groups: [...groups],
      }
    });
  }

  const upsetFeatures = async (productId, features) => {
    const updatedProduct = await featureService.upsertFeatures(productId, features);
    await initProductFeatures(productId);

    dispatch({
      type: PRODUCT_CHANGE,
      payload: updatedProduct
    });
  }

  const createProduct = async (allergens, amount, amountMode, description, erpId, families, name, photoUrls, salesFormat, stock, taxId, featured, features, specialLabel) => {

    const newProduct =
      await productService.createProduct(allergens, amount, amountMode, description, erpId, families, name, photoUrls, salesFormat, stock, taxId, featured, undefined, features, specialLabel);

    if (newProduct) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: newProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido crear el producto'
      });
    }
  };

  const updateProduct = async (id, allergens, amount, amountMode, description, erpId, familyIds, name, photoUrls, salesFormat, stock, taxId, featured, features, specialLabel) => {
    const updateProduct =
      await productService.updateProduct(id, allergens, amount, amountMode, description, erpId, familyIds, name, photoUrls, salesFormat, stock, taxId, featured, undefined, features, specialLabel);

    if (updateProduct) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: updateProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido actualizar el producto'
      });
    }
  };

  const clearProducts = () => {
    dispatch({
      type: CLEAR_PRODUCTS
    });
  };

  const showDeleteDialog = (product) => {
    dispatch({
      type: SHOW_DELETE_DIALOG,
      payload: product
    });
  };

  const dismissDeleteDialog = () => {
    dispatch({
      type: DISMISS_DELETE_DIALOG
    });
  };

  const deleteProduct = async productId => {
    const productDeleted = await productService.deleteProduct(productId);

    if (productDeleted) {
      dispatch({
        type: DELETE_PRODUCT,
        payload: productDeleted
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido eliminar el producto'
      });
    }
  };

  const importProducts = async data => {
    dispatch({
      type: INIT_IMPORTS
    });

    let success = 0;
    let errors = 0;
    await Promise.all(data.map(product =>
      productService.createImportProduct(product)
        .then(_ => success += 1)
        .catch(_ => errors += 1)));

    dispatch({
      type: IMPORT_PRODUCTS_RESPONSE,
      payload: {
        success,
        errors
      }
    });

    productService.fetchProducts(1, '').then(productResponse => {
      dispatch({
        type: CHANGE_PRODUCTS,
        payload: {
          products: productResponse.data ? productResponse.data : [],
          totalProducts: productResponse.headers
            ? parseInt(productResponse.headers['x-total-count'])
            : productResponse.data.length
        }
      });
    });
  };

  const clearImportExportResponse = () => {
    dispatch({
      type: CLEAR_IMPORT_EXPORT_RESPONSE
    });
  };

  const exportProducts = async filter => {

    const { category, query, outStock } = filter;

    const productResponse = category !== 'all'
      ? await productService.fetchProductsByFamily(category, 0, query, outStock ? 0 : undefined, 10000)
      : await productService.fetchProducts(0, query, outStock ? 0 : undefined, 10000);

      const productsExported = [];
      let exportableFeatures;

      for (const productData of productResponse.data) {
        exportableFeatures = "";
          if (productData.features) {
            const features = await featureService.findFeaturesByProductIdAsync(productData.id);
                // El formato es el siguiente TALLA(M#L#XL)|COLOR(Rojo#Verde#Negro);
            const featuresGrouped = _groupBy(features, feature => feature.group);
            for (const [key, value] of featuresGrouped) {
              const labels = value.map(f => f.label);
              exportableFeatures += `${key}(${labels.join('#')})|`;
            }
            exportableFeatures = exportableFeatures.substring(0, exportableFeatures.length -1);
          }

          productsExported.push({
            'ID': productData.erpId,
            'NOMBRE': productData.name,
            'DESCRIPCION': productData.description,
            'FAMILIA': productData.families.length > 0 ? productData.families[0].erpId : '',
            'FOTO_URL_1': productData.photoUrls.length > 0 ? productData.photoUrls[0] : '',
            'FOTO_URL_2': productData.photoUrls.length > 1 ? productData.photoUrls[1] : '',
            'FOTO_URL_3': productData.photoUrls.length > 2 ? productData.photoUrls[2] : '',
            'STOCK': productData.stock ? productData.stock : -1,
            'FEATURES': exportableFeatures
          });
      }    
        dispatch({
          type: EXPORT_PRODUCTS_RESPONSE,
          payload: productsExported
        });
  };

  const editingFeature = async group => {
    dispatch({
      type: EDIT_FEATURE,
      payload: group
    });
  }

  return (
    <ProductContext.Provider value={{
      apiError: state.error,
      editFeature: state.editFeature,
      featureGroupSelected: state.featureGroupSelected,
      filters: state.filters,
      loading: state.loading,
      productChanged: state.productChanged,
      productPicked: state.productPicked,
      products: state.products,
      productsExported: state.productsExported,
      productsImported: state.productsImported,
      showDelete: state.showDelete,
      totalProducts: state.totalProducts,

      addImagesToPickedProduct,
      clearImportExportResponse,
      clearProducts,
      createProduct,
      deleteProduct,
      dismissDeleteDialog,
      editingFeature,
      exportProducts,
      filtersChange,
      importProducts,
      initProductFeatures,
      pickProduct,
      showDeleteDialog,
      updateProduct,
      upsetFeatures,
    }}> {props.children}
    </ProductContext.Provider>
  );
};

export default ProductState;
