import {
  ADD_IMAGES_TO_PICK_PRODUCT,
  CHANGE_FEATURES,
  CHANGE_FILTER,
  CHANGE_PRODUCTS,
  CLEAR_IMPORT_EXPORT_RESPONSE,
  CLEAR_PRODUCTS,
  DELETE_PRODUCT,
  DISMISS_DELETE_DIALOG, EDIT_FEATURE,
  EXPORT_PRODUCTS_RESPONSE,
  IMPORT_PRODUCTS_RESPONSE,
  INIT_IMPORTS,
  LAUNCH_ERROR,
  PICK_PRODUCT,
  PRODUCT_CHANGE,
  SHOW_DELETE_DIALOG
} from './ProductActions';

export default (state, action) => {
  switch (action.type) {
    case CHANGE_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        totalProducts: action.payload.totalProducts,
        loading: false,
        productPicked: null,
        productChanged: null,
        error: null
      }
    case CHANGE_FEATURES:
      return {
        ...state,
        features: action.payload['features'],
        featuresGroups: action.payload['groups'],
        featureGroupSelected: undefined,
      }
    case CHANGE_FILTER:
      return {
        ...state,
        loading: true,
        filters: action.payload
      }
    case EDIT_FEATURE:
      return {
        ...state,
        featureGroupSelected: action.payload ?? 'new'
      }
    case PICK_PRODUCT:
      return {
        ...state,
        productPicked: action.payload,
        productChanged: null,
        error: null
      }
    case ADD_IMAGES_TO_PICK_PRODUCT:
      return {
        ...state,
        productPicked: {
          ...state.productPicked,
          photoUrls: [...state.productPicked.photoUrls, ...action.payload]
        }
      }
    case PRODUCT_CHANGE:
      return {
        ...state,
        productChanged: action.payload,
        products: [...state.products].map(product => product.id === action.payload.id ? action.payload : product),
        error: null
      }
    case LAUNCH_ERROR:
      return {
        ...state,
        productChanged: null,
        error: action.payload
      }
    case CLEAR_PRODUCTS:
      return {
        ...state,
        productPicked: null,
        productChanged: null,
        error: null
      }
    case DELETE_PRODUCT:
      return {
        ...state,
        products: [...state.products].filter(product => product.id !== action.payload.id),
        productChanged: action.payload,
        totalProducts: state.totalProducts - 1
      }
    case SHOW_DELETE_DIALOG:
      return {
        ...state,
        productPicked: action.payload,
        showDelete: true
      }
    case DISMISS_DELETE_DIALOG:
      return {
        ...state,
        productPicked: null,
        showDelete: false,
        productChanged: null,
        error: null
      }
    case IMPORT_PRODUCTS_RESPONSE:
      return {
        ...state,
        productsImported: action.payload,
        loading: false
      }

    case EXPORT_PRODUCTS_RESPONSE:
      return {
        ...state,
        productsExported: action.payload,
        loading: false
      }
    case CLEAR_IMPORT_EXPORT_RESPONSE:
      return {
        ...state,
        productsImported: null,
        productsExported: null
      }
      case INIT_IMPORTS:
        return {
          ...state,
          loading: true
        }
    default:
      return state;

  }
}
