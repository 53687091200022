import { GET_PAYMENT_METHODS } from './PaymentMethodActions';

export default (state, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload
      };
    default:
      return state;
  }
}
