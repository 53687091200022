import ShippingAreaContext from './ShippingAreaContext';
import React, { useEffect, useReducer } from 'react';
import {
  CHANGE_API_RESULT,
  CREATE_SHIPPING_AREA,
  FETCH_PROVINCES,
  FETCH_SHIPPING_AREAS,
  REMOVE_SHIPPING_AREA, UPDATE_SHIPPING_AREA
} from './ShippingAreaActions';
import shippingAreaService from '../../services/shippingAreaService';
import ShippingAreaReducer from './ShippingAreaReducer';

const ShippingAreaState = props => {
  const initialState = {
    apiResult: null,
    provinces: [],
    shippingAreas: []
  }

  useEffect(() => {
    shippingAreaService.fetchProvinces().then(provinces => {
      dispatch({
        type: FETCH_PROVINCES,
        payload: provinces
      })
    })
  }, []);

  const fetchShippingAreas = () => {
    shippingAreaService.fetchShippingAreas().then(shippingAreas => {
        dispatch({
          type: FETCH_SHIPPING_AREAS,
          payload: shippingAreas
        })
    })
  }

  const fetchProvinces = () => {
    shippingAreaService.fetchProvinces().then(provinces => {
      dispatch({
        type: FETCH_PROVINCES,
        payload: provinces
      })
    })
  }

  const clearApiResult = () => {
    dispatch({
      type: CHANGE_API_RESULT,
      payload: null
    })
  }

  const createShippingArea = async shippingAreaForm => {
    const {
      companyCanEditAddresses,
      country,
      dealerInfo,
      defaultsCompany,
      defaultsRetail,
      provinces,
      retailCanEditAddresses,
      shippingMethodsId
    } = shippingAreaForm;

    const newShippingArea = await shippingAreaService.createShippingArea(
      companyCanEditAddresses,
      country,
      dealerInfo,
      defaultsCompany,
      defaultsRetail,
      provinces,
      retailCanEditAddresses,
      shippingMethodsId
    );

    if (newShippingArea) {
      dispatch({
        type: CREATE_SHIPPING_AREA,
        payload: newShippingArea
      });
    } else {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido crear la Zona de Envío'
        }
      });
    }
  }
  const deleteShippingArea = async shippingAreaId => {
    const deletedShippingArea = await shippingAreaService.deleteShippingArea(shippingAreaId);

    if (deletedShippingArea) {
      dispatch({
        type: REMOVE_SHIPPING_AREA,
        payload: shippingAreaId
      })
    } else {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido eliminar la zona de envío'
        }
      })
    }
  }

  const updateShippingArea = async (shippingAreaId, shippingAreaForm) => {
    const {
      companyCanEditAddresses,
      country,
      dealerInfo,
      defaultsCompany,
      defaultsRetail,
      provinces,
      retailCanEditAddresses,
      shippingMethodsId
    } = shippingAreaForm;

    const updatedShippingArea = await shippingAreaService.updateShippingArea(
      shippingAreaId,
      companyCanEditAddresses,
      country,
      dealerInfo,
      defaultsCompany,
      defaultsRetail,
      provinces,
      retailCanEditAddresses,
      shippingMethodsId
    );

    if (updatedShippingArea) {
      dispatch({
        type: UPDATE_SHIPPING_AREA,
        payload: updatedShippingArea
      });
    } else {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido crear la Zona de Envío'
        }
      });
    }
  }

  const [state, dispatch] = useReducer(ShippingAreaReducer, initialState);

  return (
    <ShippingAreaContext.Provider value={{
      apiResult: state.apiResult,
      provinces: state.provinces,
      shippingAreas: state.shippingAreas,

      clearApiResult,
      createShippingArea,
      deleteShippingArea,
      fetchProvinces,
      fetchShippingAreas,
      updateShippingArea
    }}>
      { props.children }
    </ShippingAreaContext.Provider>
  )

}

export default ShippingAreaState;
