import {
  ADD_SHIPPING_METHOD,
  CHANGE_API_RESULT,
  FETCH_SHIPPING_METHODS,
  REMOVE_SHIPPING_METHOD, UPDATE_SHIPPING_METHOD
} from './ShippingMethodActions';

export default (state, action) => {
  switch (action.type) {
    case ADD_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethods: [...state.shippingMethods, action.payload],
        apiResult: {
          type: 'success',
          message: 'Método de envío añadido correctamente'
        }
      }
    case CHANGE_API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    case FETCH_SHIPPING_METHODS:
      return {
        ...state,
        shippingMethods: action.payload
      }
    case REMOVE_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethods: [...state.shippingMethods].filter(shippingMethod => shippingMethod.id !== action.payload),
        apiResult: {
          type: 'success',
          message: 'Método de envío eliminado correctamente'
        }
      }
    case UPDATE_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethods: [...state.shippingMethods].map(shippingMethod => shippingMethod.id === action.payload.id ? action.payload : shippingMethod),
        apiResult: {
          type: 'success',
          message: 'Método de envío actualizado correctamente'
        }
      }
    default:
      return state;
  }
}
