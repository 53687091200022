import axios from '../utils/axiosDavo';

class VenueService {

  fetchVenues = () => axios.get(`/venues`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  getVenueById = venueId => axios.get(`/venues/${venueId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return undefined;
    });

  updateVenueSchedule = (venueId, schedules) =>
    axios.put(`/venues/${venueId}`, {schedules})
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return undefined;
      });

  changeVenueSalesStatus = (venueId, salesMessageBlocked) =>
    axios.put(`/venues/${venueId}`, {salesMessageBlocked})
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return undefined;
      })
}

const venueService = new VenueService();

export default venueService;
