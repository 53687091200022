import {
  ADD_HOME_SECTION,
  CLEAR_API_RESPONSE,
  DELETE_HOME_SECTION,
  HOME_SECTIONS_CHANGE,
  SEND_API_RESPONSE,
  UPDATE_HOME_SECTION
} from './HomeSectionActions';

export default (state, action) => {
  switch (action.type) {
    case HOME_SECTIONS_CHANGE:
      return {
        ...state,
        homeSections: action.payload
      }
    case ADD_HOME_SECTION:
      return {
        ...state,
        homeSections: [...state.homeSections, action.payload],
        apiResponse: {
          type: 'success',
          message: 'Sección creada correctamente'
        }
      }
    case CLEAR_API_RESPONSE:
      return {
        ...state,
        apiResponse: null
      }

    case SEND_API_RESPONSE:
      return {
        ...state,
        apiResponse: action.payload
      }
    case UPDATE_HOME_SECTION:
      return {
        ...state,
        homeSections: [...state.homeSections].map(homeSection => homeSection.id === action.payload.id ? action.payload : homeSection),
        apiResponse: {
          type: 'success',
          message: 'Sección actualizada correctamente'
        }
      }
    case DELETE_HOME_SECTION:
      return {
        ...state,
        homeSections: [...state.homeSections].filter(homeSection => homeSection.id !== action.payload.id),
        apiResponse: {
          type: 'success',
          message: 'Sección eliminada correctamente'
        }
      }
    default:
      return state;
  }
}
