import axios from '../utils/axiosDavo';

class TaxService {

  fetchTaxes = () => axios.get(`/taxes`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });
}

const taxService = new TaxService();

export default taxService;
