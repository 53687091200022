import { ADD_COUPON, FETCH_COUPONS, CHANGE_API_RESULT, UPDATE_COUPON } from './CouponActions';

export default (state, action) => {
  switch (action.type) {
    case ADD_COUPON:
      return {
        ...state,
        coupons: [...state.coupons, action.payload],
        apiResult: {
          message: "Cupón añadido correctamente",
          type: 'success'
        }
      }
    case CHANGE_API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    case FETCH_COUPONS:
      return {
        ...state,
        coupons: action.payload
      }
    case UPDATE_COUPON:
      return {
        ...state,
        coupons: [...state.coupons].map(coupon => coupon.id === action.payload.id ? action.payload : coupon),
        apiResult: {
          message: "Cupón actualizado correctamente",
          type: 'success'
        }
      }
    default:
      return state;
  }
}
