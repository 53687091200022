import {
  ADD_FAMILY,
  API_RESULT,
  CLEAN_API_RESULT,
  DELETE_FAMILY,
  FAMILIES_CHANGE,
  UPDATE_FAMILY
} from './FamilyActions';

export default (state, action) => {
  switch (action.type) {
    case FAMILIES_CHANGE:
      return {
        ...state,
        families: action.payload
      };
    case API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      };
    case CLEAN_API_RESULT:
      return {
        ...state,
        apiResult: null
      };
    case ADD_FAMILY:
      return {
        ...state,
        families: [...state.families, action.payload],
        apiResult: {
          action: 'post',
          type: 'success',
          message: 'Categoría creada correctamente'
        }
      }
    case UPDATE_FAMILY:
      return {
        ...state,
        families: [...state.families].map(family => family.id === action.payload.id ? action.payload : family),
        apiResult: {
          action: 'put',
          type: 'success',
          message: 'Categoría actualizada correctamente'
        }
      }

    case DELETE_FAMILY:
      return {
        ...state,
        families: [...state.families].filter(family => family.id !== action.payload.id),
        apiResult: {
          action: 'delete',
          type: 'success',
          message: 'Categoría eliminada correctamente'
        }
      }
    default:
      return state;
  }
}
