import axios from '../utils/axiosDavo';

class ShippingAreaService {

  fetchShippingAreas = () => axios.get(`/shipping_areas`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  findShippingArea = id => axios.get(`/shipping_areas/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return null;
    });

  fetchProvinces = () => axios.get('/locations?type=province')
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return [];
    })

  deleteShippingArea = shippingAreaId => axios.delete(`/shipping_areas/${shippingAreaId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return null;
    })

  createShippingArea = (companyCanEditAddresses, country, dealerInfo, defaultsCompany, defaultsRetail, provinces, retailCanEditAddresses, shippingMethodsId) =>
    axios.post('shipping_areas', {
      companyCanEditAddresses,
      country,
      dealerInfo,
      defaultsCompany,
      defaultsRetail,
      provinces,
      retailCanEditAddresses,
      shippingMethodsId
    }).then(response => response.data).catch(error => console.error(error));

  updateShippingArea = (id, companyCanEditAddresses, country, dealerInfo, defaultsCompany, defaultsRetail, provinces, retailCanEditAddresses, shippingMethodsId) =>
    axios.put(`shipping_areas/${id}`, {
      companyCanEditAddresses,
      country,
      dealerInfo,
      defaultsCompany,
      defaultsRetail,
      provinces,
      retailCanEditAddresses,
      shippingMethodsId
    }).then(response => response.data).catch(error => console.error(error));


}


const shippingAreaService = new ShippingAreaService();

export default shippingAreaService;
