import axios from '../utils/axiosDavo';

class VoucherService {

  createExchange = (amount, userId, voucherId) => axios.post(`vouchers/${voucherId}/exchanges`, {amount, userId}).then(result => result.data).catch(error => {
    console.log(error);
    return undefined;
  })

  createVoucher = voucherInfo => axios.post(`vouchers`, voucherInfo).then(response => response.data)
    .catch(error => {
    console.log(error);
    return undefined;
  });

  fetchVouchers = (query, status, page, limit) =>
    axios.get(`/vouchers?search_text=${query}&status=${status}&offset=${page * limit}&limit=${limit}`)
    .catch(error => {
      console.error(error)
      return [];
    });

  findVoucherById = voucherId => axios.get(`/vouchers/${voucherId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return undefined;
    });
}

const voucherService = new VoucherService();

export default voucherService;
