import axios from '../utils/axiosDavo';

class FeatureService {

  findFeaturesByProductIdAsync = (productId) => axios.get(`/products/${productId}/features`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  upsertFeatures = (productId, features) => axios.put(`/products/${productId}/features`, { features })
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return [];
    });
}

const featureService = new FeatureService();

export default featureService;
