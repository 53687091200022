import VoucherContext from './VoucherContext';
import { API_RESULT, CREATE_VOUCHER, PICK_VOUCHER, UPDATE_VOUCHER, VOUCHERS_CHANGE } from './VoucherActions';
import React, { useReducer } from 'react';
import VoucherReducer from './VoucherReducer';
import { API_RESPONSE, CLEAR_API_STATUS } from '../orders/OrderActions';
import voucherService from '../../services/voucherService';
import authService from '../../services/authService';

const VoucherState = props => {
  const initialState = {
    vouchers: [],
    voucherPicked: null,
    loading: false,
    apiResponse: null,
    totalVouchers: 0,
    filters: {
      page: 0,
      query: '',
      status: 'all'
    },
  }

  const [state, dispatch] = useReducer(VoucherReducer, initialState);

  const changeFilters = filters => {
    _fetchVouchers(filters.query, filters.status, filters.page);
  }

  const _fetchVouchers = async (query = '', status = 'all', page = 0, limit = 20) => {
    const vouchersResponse = await voucherService.fetchVouchers(query, status, page, limit);

    if (vouchersResponse) {
      dispatch({
        type: VOUCHERS_CHANGE,
        payload: {
          filters: {
            query,
            status,
            page
          },
          vouchers: vouchersResponse.data ? vouchersResponse.data : [],
          totalVouchers: vouchersResponse.headers
            ? parseInt(vouchersResponse.headers["x-total-count"])
            : vouchersResponse.data.length
        }
      })
    } else {
      dispatch({
        type: API_RESPONSE,
        payload: {
          action: 'get',
          status: 'error',
          message: 'No se han podido cargar los bonos'
        }
      })
    }
  };

  const clearApiStatus = () => {
    dispatch({
      type: CLEAR_API_STATUS
    })
  };

  const createExchange = async (amount, voucherId) => {
    const updatedVoucher = await voucherService.createExchange(amount, authService.getUserId(), voucherId);

    if (!updatedVoucher) {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'post',
          status: 'error',
          message: 'No se ha podido canjear el bono'
        }
      });
    } else {
      dispatch({
        type: UPDATE_VOUCHER,
        payload: updatedVoucher
      });
    }
  }

  const createVoucher = async voucherInfo => {
    const newVoucher = await voucherService.createVoucher(voucherInfo);

    if (!newVoucher) {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'post',
          status: 'error',
          message: 'No se ha podido crear el bono'
        }
      })
    } else {
      dispatch({
        type: CREATE_VOUCHER,
        payload: newVoucher
      })
    }
  }

  const getVoucherById = async voucherId => {
    const voucher = await voucherService.findVoucherById(voucherId);

    if (!voucher) {
      dispatch({
        type: API_RESULT,
        payload: {
          action: 'get',
          status: 'error',
          message: 'No se ha podido cargar el bono'
        }
      });
    } else {
      dispatch({
        type: PICK_VOUCHER,
        payload: voucher
      });
    }
  }

  return (
    <VoucherContext.Provider value={{
      vouchers: state.vouchers,
      voucherPicked: state.voucherPicked,
      apiResult: state.apiResult,
      filters: state.filters,
      loading: state.loading,
      totalVouchers: state.totalVouchers,

      changeFilters,
      clearApiStatus,
      createExchange,
      createVoucher,
      getVoucherById
    }}>
      { props.children }
    </VoucherContext.Provider>
  )
}

export default VoucherState;
