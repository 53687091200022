import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PartnerContext from 'src/hooks/partners/PartnerContext';
import TextField from '@material-ui/core/TextField';

export default function ManageCloseShopDialog({handleClose, open}) {
  const { partner, updateSalesMessageBlocked } = useContext(PartnerContext);
  const [salesMessageBlocked, setSalesMessageBlocked] = useState('');
  const [enableAction, setEnableAction] = useState(false);
  const shopClosed = partner?.config?.salesBlockedMessage?.length;

  useEffect(() => {
    setEnableAction(shopClosed || salesMessageBlocked.length);
  }, [salesMessageBlocked, shopClosed]);

  const handleAction = async () => {
    if (shopClosed) await updateSalesMessageBlocked('open');
    else await updateSalesMessageBlocked(salesMessageBlocked);

    handleClose();
  }

  const OpenShopText = (
    <DialogContentText id="alert-dialog-description">
      Vas a permitir las compras en tu tienda. Tus clientes y comerciales podrán volver a realizar pedidos. ¿Estás seguro que quieres volver a permitir compras?
    </DialogContentText>
  );

  const CloseShopTextArea = (
    <TextField
      fullWidth
      label="Mensaje para clientes"
      name="salesMessageBlocked"
      onChange={(event) => setSalesMessageBlocked(event.target.value)}
      value={salesMessageBlocked}
      variant="outlined"
    />
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{shopClosed ? 'Permitir Pedidos' : 'Bloquear Pedidos'}</DialogTitle>
        <DialogContent>
          { shopClosed ? OpenShopText : CloseShopTextArea }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            disabled={!enableAction}
            onClick={handleAction} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
