import axios from '../utils/axiosDavo';

class FeeService {

  fetchFees = () => axios.get(`/fees`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  createFee = fee => axios.post('/fees', fee)
    .then(response => response.data)
    .catch(error => console.error(error))
}

const feeService = new FeeService();

export default feeService;
