import axios from '../utils/axiosDavo';

class OrderService {

  fetchOrders = (query, status, type, page, limit) =>
    axios.get(`/orders?search_text=${query}&status=${status}&type=${type}&offset=${page * limit}&limit=${limit}`)
    .catch(error => {
      console.error(error)
      return []
    });

  findOrderById = orderId => axios.get(`/orders/${orderId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return undefined;
    });

  findByClientId = clientId => axios.get(`/clients/${clientId}/orders`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  updateOrderDetails = (clientId, orderId, details) =>
    axios.put(`/clients/${clientId}/orders/${orderId}`, {details})
      .then(response => response.data)
      .catch(error => {
        console.error(error)
        return undefined;
      });

  updateStatusOrder = (clientId, orderId, status) =>
    axios.put(`/clients/${clientId}/orders/${orderId}`, {status})
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return undefined;
    });

  updateOrderPaymentInfo = (clientId, orderId, paymentInfo) =>
    axios.put(`/clients/${clientId}/orders/${orderId}`, {paymentInfo})
      .then(response => response.data)
      .catch(error => {
        console.error(error)
        return undefined;
      });

  importOrder = ({clientErpId, orderErpId, paymentMethodErpId, shippingMethodErpId, finalPrice, taxes}) =>
    axios.post('orders', {
      clientErpId,
      orderErpId,
      paymentMethodErpId,
      shippingMethodErpId,
      finalPrice,
      taxes,
      from: 'csv'
    })

  importDetail = (orderErpId, details) =>
    axios.put('orders', {
      orderErpId,
      details,
      from: 'csv'
    })

  sendOrderEmail = orderId => axios.post(`orders/${orderId}/emails?type=resend_client`)
}

const orderService = new OrderService();

export default orderService;
