import React, { useReducer } from 'react';
import ProductReducer from './VoucherProductReducer';
import VoucherProductContext from './VoucherProductContext';
import productService from '../../services/productService';
import {
  ADD_IMAGES_TO_PICK_PRODUCT,
  CHANGE_FILTER,
  CHANGE_PRODUCTS,
  CLEAR_PRODUCTS,
  DELETE_PRODUCT,
  DISMISS_DELETE_DIALOG,
  LAUNCH_ERROR,
  PICK_PRODUCT,
  PRODUCT_CHANGE,
  SHOW_DELETE_DIALOG
} from './VoucherProductActions';

const VoucherProductState = props => {
  const initialState = {
    products: [],
    totalProducts: 0,
    loading: false,
    productPicked: null,
    productChanged: null,
    error: null,
    filters: {
      outStock: false,
      query: null,
      page: 0
    },
    showDelete: false,
    productsImported: null,
    productsExported: null
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  const filtersChange = async filter => {
    dispatch({
      type: CHANGE_FILTER,
      payload: filter
    });

    const { page, query, outStock } = filter;

    const productResponse = await productService.fetchVoucherProducts(page, query, outStock ? 0 : undefined);

    dispatch({
      type: CHANGE_PRODUCTS,
      payload: {
        products: productResponse.data ? productResponse.data : [],
        totalProducts: productResponse.headers
          ? parseInt(productResponse.headers['x-total-count'])
          : productResponse.data.length
      }
    });
  };

  const pickProduct = async productId => {
    const product = await productService.findProductById(productId);

    if (product) {
      dispatch({
        type: PICK_PRODUCT,
        payload: product
      });
    }

  };

  const addImagesToPickedProduct = newImages => {
    dispatch({
      type: ADD_IMAGES_TO_PICK_PRODUCT,
      payload: newImages
    });
  };

  const createProduct = async (allergens, amount, amountMode, description, erpId, families, name, photoUrls, salesFormat, stock, taxId, featured, voucherInfo) => {
    const newProduct =
      await productService.createProduct(allergens, amount, amountMode, description, erpId, families, name, photoUrls, salesFormat, stock, taxId, featured, voucherInfo);

    if (newProduct) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: newProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido crear el producto'
      });
    }
  };

  const updateProduct = async (id, allergens, amount, amountMode, description, erpId, familyIds, name, photoUrls, salesFormat, stock, taxId, featured, voucherInfo) => {
    const updateProduct =
      await productService.updateProduct(id, allergens, amount, amountMode, description, erpId, familyIds, name, photoUrls, salesFormat, stock, taxId, featured, voucherInfo);

    if (updateProduct) {
      dispatch({
        type: PRODUCT_CHANGE,
        payload: updateProduct
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido actualizar el producto'
      });
    }
  };

  const clearProducts = () => {
    dispatch({
      type: CLEAR_PRODUCTS
    });
  };

  const showDeleteDialog = (product) => {
    dispatch({
      type: SHOW_DELETE_DIALOG,
      payload: product
    });
  };

  const dismissDeleteDialog = () => {
    dispatch({
      type: DISMISS_DELETE_DIALOG
    });
  };

  const deleteProduct = async productId => {
    const productDeleted = await productService.deleteProduct(productId);

    if (productDeleted) {
      dispatch({
        type: DELETE_PRODUCT,
        payload: productDeleted
      });
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido eliminar el producto'
      });
    }
  };

  return (
    <VoucherProductContext.Provider value={{
      apiError: state.error,
      filters: state.filters,
      loading: state.loading,
      productChanged: state.productChanged,
      productPicked: state.productPicked,
      products: state.products,
      productsExported: state.productsExported,
      productsImported: state.productsImported,
      showDelete: state.showDelete,
      totalProducts: state.totalProducts,
      addImagesToPickedProduct,
      clearProducts,
      createProduct,
      deleteProduct,
      dismissDeleteDialog,
      filtersChange,
      pickProduct,
      showDeleteDialog,
      updateProduct
    }}> {props.children}
    </VoucherProductContext.Provider>
  );
};

export default VoucherProductState;
