import { LOG_USER_CREATE, LOG_USER_FETCH } from './LogUserActions';

export default (state, action) => {
  switch (action.type) {
    case LOG_USER_FETCH:
      return {
        ...state,
        logs: action.payload
      };
      case LOG_USER_CREATE:
        return {
          ...state,
          logs: [action.payload, ...state.logs]
        }
    default:
      return state;
  }
}
