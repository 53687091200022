import PartnerContext from './PartnerContext';
import React, { useEffect, useReducer } from 'react';
import { FETCH_PARTNER, UPDATE_SALES_MESSAGE } from './PartnerActions';
import partnerService from '../../services/partnerService';
import PartnerReducer from './PartnerReducer';

const PartnerState = props => {
  const initialState = {
    partner: null
  }

  useEffect(() => {
    partnerService.fetchPartner().then(partner => {
      localStorage.setItem('type', partner.type);
      dispatch({
        type: FETCH_PARTNER,
        payload: partner
      });
    })
  }, []);

  const [state, dispatch] = useReducer(PartnerReducer, initialState);

  const updateSalesMessageBlocked = async (salesMessageBlocked) => {
    const partnerUpdated = await partnerService.updateSalesMessageBlocked(salesMessageBlocked);

    if (partnerUpdated) {
      dispatch({
        type: UPDATE_SALES_MESSAGE,
        payload: partnerUpdated
      });
    }
  }

  return (
    <PartnerContext.Provider value={{
      partner: state.partner,

      updateSalesMessageBlocked
    }}>
      { props.children }
    </PartnerContext.Provider>
  )

}

export default PartnerState;
