import React, { useEffect, useReducer } from 'react';
import PriceContext from './PriceContext';
import {
  CHANGE_FILTER,
  CHANGE_PRICES,
  CLEAR_IMPORT_EXPORT_RESPONSE,
  CLEAR_PRICES,
  CLEAR_PRODUCT,
  DELETE_ALL_PRICES_BY_PRODUCT,
  DELETE_PRICE,
  DISMISS_DELETE_DIALOG,
  EXPORT_PRICES_RESPONSE,
  IMPORT_PRICES_RESPONSE,
  INIT_IMPORTS,
  LAUNCH_ERROR,
  PICK_PRICE,
  PICK_PRODUCT,
  SHOW_DELETE_DIALOG,
  UPSERT_PRICE
} from './PriceActions';
import PriceReducer from './PriceReducer';
import priceService from '../../services/priceService';

const PriceState = props => {
  const initialState = {
    prices: [],
    totalPrices: 0,
    pricePicked: null,
    priceChanged: null,
    error: null,
    filters: {
      fee: null,
      query: null,
      page: 0,
      product: null
    },
    productPrices: [],
    pricesImported: null,
    pricesExported: null,
    loading: false
  }

  const [state, dispatch] = useReducer(PriceReducer, initialState);

  useEffect(() => {
    priceService.fetchPrices(0, '').then(priceResponse => {
      dispatch({
        type: CHANGE_PRICES,
        payload: {
        prices: priceResponse.data ? priceResponse.data : [],
          totalPrices: priceResponse.headers
          ? parseInt(priceResponse.headers["x-total-count"])
          : priceResponse.data.length
      }
      })
    })
  }, [])

  const filtersChange = async filter => {
    dispatch({
      type: CHANGE_FILTER,
      payload: filter
    })

    const { fee, page, query } = filter

    const priceResponse = fee
      ? await priceService.fetchPricesByFee(fee, page, query)
      : await priceService.fetchPrices(page, query)

    dispatch({
      type: CHANGE_PRICES,
      payload: {
        prices: priceResponse.data ? priceResponse.data : [],
        totalPrices: priceResponse.headers
          ? parseInt(priceResponse.headers["x-total-count"])
          : priceResponse.data.length
      }
    })
  }

  const pickPrice = async priceId => {
    const price = await priceService.findPriceById(priceId);

    dispatch({
      type: PICK_PRICE,
      payload: price
    })
  }

  const upsertPrice = async (feeId, productId, compareTo, value) => {
    if (compareTo > 0) {
      const salePrice = compareTo;
      compareTo = value;
      value = salePrice;
    }

    const updatedPrice =
      await priceService.upsertPriceByFee(feeId, productId, compareTo, value);

    if (updatedPrice) {
      dispatch({
        type: UPSERT_PRICE,
        payload: updatedPrice
      })
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido actualizar el producto'
      })
    }
  }

  const clearProducts = () => {
    dispatch({
      type: CLEAR_PRODUCT
    })
  }

  const clearPrices = () => {
    dispatch({
      type: CLEAR_PRICES
    })
  }

  const showDeleteDialog = (price) => {
    dispatch({
      type: SHOW_DELETE_DIALOG,
      payload: price
    })
  }

  const dismissDeleteDialog = () => {
    dispatch({
      type: DISMISS_DELETE_DIALOG
    })
  }

  const deletePrice = async priceId => {
    const priceDeleted = await priceService.deletePrice(priceId);

    if (priceDeleted) {
      dispatch({
        type: DELETE_PRICE,
        payload: priceDeleted
      })
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido eliminar el precio'
      })
    }
  }

  const deletePricesByProductId = async productId => {
    const deletePricesResponse = await priceService.deleteByProductId(productId);

    if (deletePricesResponse) {
      dispatch({
        type: DELETE_ALL_PRICES_BY_PRODUCT,
        payload: productId
      })
    } else {
      dispatch({
        type: LAUNCH_ERROR,
        payload: 'No se ha podido eliminar el precio'
      })
    }
  }

  const pickProductFromPriceId = async priceId => {
    const product = await priceService.findById(priceId).then(price => price.product);

    if (product) {
      pickProduct(product);
    }
  }

  const pickProduct = async product => {
    const productPrices = product
      ? await priceService.findByProductId(product.id)
      : []

    dispatch({
      type: PICK_PRODUCT,
      payload: {
        productPicked: product,
        productPrices
      }
    })
  };

  const importPrices = async data => {
    dispatch({
      type: INIT_IMPORTS
    });

    let success = 0;
    let errors = 0;

    await Promise.all(data.map(price => priceService.importPrice(price)
      .then(_ => success += 1)
      .catch(_ => errors += 1)));

    dispatch({
      type: IMPORT_PRICES_RESPONSE,
      payload: {
        success,
        errors
      }
    });

    priceService.fetchPrices(0, '').then(priceResponse => {
      dispatch({
        type: CHANGE_PRICES,
        payload: {
          prices: priceResponse.data ? priceResponse.data : [],
          totalPrices: priceResponse.headers
            ? parseInt(priceResponse.headers["x-total-count"])
            : priceResponse.data.length
        }
      })
    })
  };

  const clearImportExportPrices = () => {
    dispatch({
      type: CLEAR_IMPORT_EXPORT_RESPONSE
    })
  };

  const exportPrices = async (filter) => {
    const { fee, query } = filter;

    const priceResponse = fee
      ? await priceService.fetchPricesByFee(fee, 0, query, 10000)
      : await priceService.fetchPrices(0, query, 10000);

    const pricesExported = priceResponse.data
      ? priceResponse.data.map(price => {
        return {
          'REF_PRODUCTO': price.product.erpId,
          'REF_TARIFA': price.fee.erpId,
          'PRECIO': price.compareTo ? price.compareTo : price.value,
          'OFERTA': price.compareTo ? price.value : undefined
        }
      })
      : [];

    dispatch({
      type: EXPORT_PRICES_RESPONSE,
      payload: pricesExported
    })
  };

  return (
    <PriceContext.Provider value={{
      apiError: state.error,
      filters: state.filters,
      loading: state.loading,
      priceChanged: state.priceChanged,
      pricePicked: state.pricePicked,
      prices: state.prices,
      pricesExported: state.pricesExported,
      pricesImported: state.pricesImported,
      productPicked: state.productPicked,
      productPrices: state.productPrices,
      showDelete: state.showDelete,
      totalPrices: state.totalPrices,
      clearImportExportPrices,
      clearPrices,
      clearProducts,
      deletePrice,
      deletePricesByProductId,
      dismissDeleteDialog,
      exportPrices,
      filtersChange,
      importPrices,
      pickPrice,
      pickProduct,
      pickProductFromPriceId,
      showDeleteDialog,
      upsertPrice
    }}>{ props.children }
    </PriceContext.Provider>
  )
};

export default PriceState;
