import {
  ADD_NOTIFICATION,
  CLEAR_API_RESPONSE, DELETE_NOTIFICATION, FILTER_BY_SHOW_IN_HOME,
  NOTIFICATIONS_CHANGE, PICK_NOTIFICATION, SEND_API_RESPONSE, UPDATE_NOTIFICATION
} from './NotificationActions';

export default (state, action) => {
  switch (action.type) {
    case NOTIFICATIONS_CHANGE:
      return {
        ...state,
        notifications: action.payload
      }
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
        apiResponse: {
          type: 'success',
          message: 'Notificación agregada correctamente'
        }
      }
    case CLEAR_API_RESPONSE:
      return {
        ...state,
        apiResponse: null
      }

    case SEND_API_RESPONSE:
      return {
        ...state,
        apiResponse: action.payload
      }
    case PICK_NOTIFICATION:
      return {
        ...state,
        notificationPicked: action.payload
      }
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notificationPicked: null,
        notifications: [...state.notifications].map(notification => notification.id === action.payload.id ? action.payload : notification),
        apiResponse: {
          type: 'success',
          message: 'Notificación actualizada correctamente'
        }
      }
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications].filter(notification => notification.id !== action.payload.id),
        apiResponse: {
          type: 'success',
          message: 'Notificación eliminada correctamente'
        }
      }
    case FILTER_BY_SHOW_IN_HOME:
      return {
        ...state,
      }
    default:
      return state;
  }
}
