/*
 * TYPE = distributio
 */
import {
  Bell as NotificationIcon,
  Folder as FolderIcon, Gift as GiftIcon, Home as HomeIcon,
  PieChart as PieChartIcon,
  ShoppingCart as ShoppingCartIcon, Truck as TruckIcon,
  Users as UsersIcon
} from 'react-feather';

const distributio = [
  {
    items: [
      {
        title: 'Informes',
        icon: PieChartIcon,
        href: '/app/management/reports'
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/customers'
          },
          {
            title: 'Empresas',
            href: '/app/management/companies'
          }
        ]
      },
      {
        title: 'Comerciales',
        icon: UsersIcon,
        href: '/app/management/commercials',
      },
      {
        title: 'Catálogo',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'Productos',
            href: '/app/management/products'
          },
          {
            title: 'Categorías',
            href: '/app/management/families'
          },
          {
            title: 'Tarifas',
            href: '/app/management/prices'
          }
        ]
      },
      {
        title: 'Pedidos',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/orders'
          },
          {
            title: 'Empresa',
            href: '/app/management/orders_company'
          }
        ]
      },
      {
        title: 'Marketing y Secciones',
        icon: NotificationIcon,
        href: '/app/management/notifications',
        items: [
          {
            title: 'Listado de Notificaciones',
            href: '/app/management/notifications'
          },
          {
            title: 'Secciones de Inicio',
            href: '/app/management/home_sections'
          }
        ]
      },
      {
        title: 'Envío',
        icon: TruckIcon,
        href: '/app/management/shipping_methods',
        items: [
          {
            title: 'Métodos de Envío',
            href: '/app/management/shipping_methods'
          },
          {
            title: 'Zonas de Envío',
            href: '/app/management/shipping_areas'
          }
        ]
      },
      {
        title: 'Locales',
        icon: HomeIcon,
        href: '/app/management/venues',
        items: [
          {
            title: 'Mis Locales',
            href: '/app/management/venues'
          }
        ]
      },
      {
        title: 'Fidelización',
        icon: GiftIcon,
        href: '/app/management/coupons',
        items: [
          {
            title: 'Cupones',
            href: '/app/management/coupons'
          }
        ]
      }
    ]
  }
];

const b2b = [
  {
    items: [
      {
        title: 'Informes',
        icon: PieChartIcon,
        href: '/app/management/reports'
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Empresas',
            href: '/app/management/companies'
          }
        ]
      },
      {
        title: 'Comerciales',
        icon: UsersIcon,
        href: '/app/management/commercials',
      },
      {
        title: 'Catálogo',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'Productos',
            href: '/app/management/products'
          },
          {
            title: 'Categorías',
            href: '/app/management/families'
          },
          {
            title: 'Tarifas',
            href: '/app/management/prices'
          }
        ]
      },
      {
        title: 'Pedidos',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Empresa',
            href: '/app/management/orders_company'
          }
        ]
      },
      {
        title: 'Marketing y Secciones',
        icon: NotificationIcon,
        href: '/app/management/notifications',
        items: [
          {
            title: 'Listado de Notificaciones',
            href: '/app/management/notifications'
          },
          {
            title: 'Secciones de Inicio',
            href: '/app/management/home_sections'
          }
        ]
      },
      {
        title: 'Envío',
        icon: TruckIcon,
        href: '/app/management/shipping_methods',
        items: [
          {
            title: 'Métodos de Envío',
            href: '/app/management/shipping_methods'
          },
          {
            title: 'Zonas de Envío',
            href: '/app/management/shipping_areas'
          }
        ]
      },
      {
        title: 'Locales',
        icon: HomeIcon,
        href: '/app/management/venues',
        items: [
          {
            title: 'Mis Locales',
            href: '/app/management/venues'
          }
        ]
      },
      {
        title: 'Fidelización',
        icon: GiftIcon,
        href: '/app/management/coupons',
        items: [
          {
            title: 'Cupones',
            href: '/app/management/coupons'
          }
        ]
      }
    ]
  }
];

const b2c = [
  {
    items: [
      {
        title: 'Informes',
        icon: PieChartIcon,
        href: '/app/management/reports'
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/customers'
          }
        ]
      },
      {
        title: 'Catálogo',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'Productos',
            href: '/app/management/products'
          },
          {
            title: 'Categorías',
            href: '/app/management/families'
          },
          {
            title: 'Tarifas',
            href: '/app/management/prices'
          }
        ]
      },
      {
        title: 'Pedidos',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/orders'
          },
        ]
      },
      {
        title: 'Marketing y Secciones',
        icon: NotificationIcon,
        href: '/app/management/notifications',
        items: [
          {
            title: 'Listado de Notificaciones',
            href: '/app/management/notifications'
          },
          {
            title: 'Secciones de Inicio',
            href: '/app/management/home_sections'
          }
        ]
      },
      {
        title: 'Envío',
        icon: TruckIcon,
        href: '/app/management/shipping_methods',
        items: [
          {
            title: 'Métodos de Envío',
            href: '/app/management/shipping_methods'
          },
          {
            title: 'Zonas de Envío',
            href: '/app/management/shipping_areas'
          }
        ]
      },
      {
        title: 'Locales',
        icon: HomeIcon,
        href: '/app/management/venues',
        items: [
          {
            title: 'Mis Locales',
            href: '/app/management/venues'
          }
        ]
      },
      {
        title: 'Fidelización',
        icon: GiftIcon,
        href: '/app/management/coupons',
        items: [
          {
            title: 'Cupones',
            href: '/app/management/coupons'
          }
        ]
      }
    ]
  }
];

const vouchers = [
  {
    items: [
      {
        title: 'Informes',
        icon: PieChartIcon,
        href: '/app/management/reports'
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/customers'
          }
        ]
      },
      {
        title: 'Catálogo',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'Productos',
            href: '/app/management/products'
          },
          {
            title: 'Bonos',
            href: '/app/management/voucher_products'
          },
          {
            title: 'Categorías',
            href: '/app/management/families'
          },
          {
            title: 'Tarifas',
            href: '/app/management/prices'
          }
        ]
      },
      {
        title: 'Pedidos',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/orders'
          },
          {
            title: 'Bonos',
            href: '/app/management/vouchers'
          }
        ]
      },
      {
        title: 'Marketing y Secciones',
        icon: NotificationIcon,
        href: '/app/management/notifications',
        items: [
          {
            title: 'Listado de Notificaciones',
            href: '/app/management/notifications'
          },
          {
            title: 'Secciones de Inicio',
            href: '/app/management/home_sections'
          }
        ]
      },
      {
        title: 'Envío',
        icon: TruckIcon,
        href: '/app/management/shipping_methods',
        items: [
          {
            title: 'Métodos de Envío',
            href: '/app/management/shipping_methods'
          },
          {
            title: 'Zonas de Envío',
            href: '/app/management/shipping_areas'
          }
        ]
      },
      {
        title: 'Fidelización',
        icon: GiftIcon,
        href: '/app/management/coupons',
        items: [
          {
            title: 'Cupones',
            href: '/app/management/coupons'
          }
        ]
      }
    ]
  }
];

const restaurants = [
  {
    items: [
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/customers'
          }
        ]
      },
      {
        title: 'Catálogo',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'Productos',
            href: '/app/management/products'
          },
          {
            title: 'Categorías',
            href: '/app/management/families'
          },
          {
            title: 'Tarifas',
            href: '/app/management/prices'
          }
        ]
      },
      {
        title: 'Pedidos',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'Particulares',
            href: '/app/management/orders'
          }
        ]
      },
      {
        title: 'Marketing y Secciones',
        icon: NotificationIcon,
        href: '/app/management/notifications',
        items: [
          {
            title: 'Listado de Notificaciones',
            href: '/app/management/notifications'
          }
        ]
      },
      {
        title: 'Locales',
        icon: HomeIcon,
        href: '/app/management/venues',
        items: [
          {
            title: 'Mis Locales',
            href: '/app/management/venues'
          }
        ]
      },
      {
        title: 'Fidelización',
        icon: GiftIcon,
        href: '/app/management/coupons',
        items: [
          {
            title: 'Cupones',
            href: '/app/management/coupons'
          }
        ]
      }
    ]
  }
];

const getNavigationByPartnerType = type => {
  switch (type) {
    case 'b2b':
      return b2b;
    case 'b2c':
      return b2c;
    case 'restaurant':
      return restaurants;
    case 'vouchers':
      return vouchers;
    default:
      return distributio;
  }

};

export default getNavigationByPartnerType;
