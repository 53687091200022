import axios from '../utils/axiosDavo';

class UserService {

  getUserById = (userId) =>
    axios.get(`/users/${userId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });
}

const userService = new UserService();

export default userService;
