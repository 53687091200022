import {
  CHANGE_FILTER,
  CHANGE_PRICES,
  CLEAR_IMPORT_EXPORT_RESPONSE,
  CLEAR_PRICES,
  CLEAR_PRODUCT,
  DELETE_ALL_PRICES_BY_PRODUCT,
  DELETE_PRICE,
  DISMISS_DELETE_DIALOG, EXPORT_PRICES_RESPONSE,
  IMPORT_PRICES_RESPONSE,
  INIT_IMPORTS,
  LAUNCH_ERROR,
  PICK_PRICE,
  PICK_PRODUCT,
  SHOW_DELETE_DIALOG,
  UPSERT_PRICE
} from './PriceActions';

export default (state, action) => {
  switch (action.type) {
    case CHANGE_PRICES:
      return {
        ...state,
        prices: action.payload.prices,
        totalPrices: action.payload.totalPrices,
        pricePicked: null,
        priceChanged: null,
        error: null,
        productPicked: null,
        productPrices: []
      }
    case CHANGE_FILTER:
      return {
        ...state,
        filters: action.payload
      }
    case PICK_PRICE:
      return {
        ...state,
        pricePicked: action.payload,
        priceChanged: null,
        error: null
      }
    case UPSERT_PRICE:
      let updated = false;
      const updatedPrices = [...state.productPrices].map(price => {
        if (price.id.toString() === action.payload.id.toString()) {
          updated = true;
          return action.payload
        } else {
          return price
        }});
      return {
        ...state,
        priceChanged: updated ? 'update' : 'create',
        prices: updated
          ? [...updatedPrices].map(price =>
            price.id === action.payload.id ? action.payload : price)
          : [...updatedPrices, action.payload],
        productPrices: updated
          ? [...state.productPrices].map(price =>
            price.id === action.payload.id ? action.payload : price)
          : [...state.productPrices, action.payload],
        error: null
      }
    case LAUNCH_ERROR:
      return {
        ...state,
        productChanged: null,
        error: action.payload
      }
    case CLEAR_PRICES:
      return {
        ...state,
        pricePicked: null,
        priceChanged: null,
        error: null
      }
    case CLEAR_PRODUCT:
      return {
        ...state,
        productPrices: [],
        productPicked: null
      }
    case DELETE_PRICE:
      return {
        ...state,
        prices: [...state.prices].filter(price => price.id !== action.payload.id),
        productPrices: [...state.productPrices].filter(price => price.id !== action.payload.id),
        priceChanged: 'delete',
        totalPrices: state.totalPrices - 1
      }
    case DELETE_ALL_PRICES_BY_PRODUCT:
      return {
        ...state,
        priceChanged: 'delete',
        prices: [...state.prices].filter(price => price.product.id !== action.payload)
      }
    case SHOW_DELETE_DIALOG:
      return {
        ...state,
        pricePicked: action.payload,
        showDelete: true
      }
    case DISMISS_DELETE_DIALOG:
      return {
        ...state,
        pricePicked: null,
        showDelete: false,
        priceChanged: null,
        error: null
      }
    case PICK_PRODUCT:
      return {
        ...state,
        productPicked: action.payload.productPicked,
        productPrices: action.payload.productPrices
      }
    case EXPORT_PRICES_RESPONSE:
      return {
        ...state,
        pricesExported: action.payload
      }
    case IMPORT_PRICES_RESPONSE:
      return {
        ...state,
        pricesImported: action.payload,
        loading: false
      }
    case CLEAR_IMPORT_EXPORT_RESPONSE:
      return {
        ...state,
        pricesImported: null,
        pricesExported: null
      }
      case INIT_IMPORTS:
        return {
          ...state,
          loading: true
        }
    default:
      return state;

  }
}
