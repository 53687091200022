import {
  CHANGE_API_RESULT,
  CREATE_SHIPPING_AREA,
  FETCH_PROVINCES,
  FETCH_SHIPPING_AREAS,
  REMOVE_SHIPPING_AREA, UPDATE_SHIPPING_AREA
} from './ShippingAreaActions';

export default (state, action) => {
  switch (action.type) {
    case CHANGE_API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    case REMOVE_SHIPPING_AREA:
      return {
        ...state,
        shippingAreas: [...state.shippingAreas].filter(shippingArea => shippingArea.id !== action.payload),
        apiResult: {
          type: 'success',
          message: 'Zona de envío eliminada correctamente'
        }
      }
    case FETCH_SHIPPING_AREAS:
      return {
        ...state,
        shippingAreas: action.payload
      }
    case FETCH_PROVINCES:
      return {
        ...state,
        provinces: action.payload
      }
    case CREATE_SHIPPING_AREA:
      return {
        ...state,
        shippingAreas: [...state.shippingAreas, action.payload],
        apiResult: {
          type: 'success',
          message: 'Zona de envío creada correctamente'
        }
      }
    case UPDATE_SHIPPING_AREA:
      return {
        ...state,
        shippingAreas: [...state.shippingAreas].map(shippingArea => shippingArea.id === action.payload.id ? action.payload : shippingArea),
        apiResult: {
          type: 'success',
          message: 'Zona de Envío actualizada correctamente'
        }
      }
    default:
      return state;
  }
}
