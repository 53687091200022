import { API_RESULT, CREATE_FEE, FEES_CHANGE } from './FeeActions';

export default (state, action) => {
  switch (action.type) {
    case FEES_CHANGE:
      return {
        ...state,
        fees: action.payload
      }
    case CREATE_FEE:
      return {
        ...state,
        fees: [...state.fees, action.payload],
        apiResult: {
          action: 'post',
          type: 'success',
          message: 'Tarifa creada correctamente'
        }
      }
    case API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    default:
      return state;
  }
}
