import axios from 'axios';
import firebaseService from './firebaseService';
import jwtDecode from 'jwt-decode';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    firebaseService.authWithMailAndPass(email, password).then(_ => {
      firebaseService.getAuthToken().then(result => {
        this.setSession(result.token)
        resolve(result.token)
      }).catch(error => reject(error))
    }).catch(error => reject(error))
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getPartnerId = () => this.isValidToken(this.getAccessToken())
    ? jwtDecode(this.getAccessToken()).partnerId
    : null

  getUserId = () => this.isValidToken(this.getAccessToken())
    ? jwtDecode(this.getAccessToken()).id
    : null

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    try {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;

      return decoded.exp > currentTime;
    } catch (e) {
      return false
    }
  }

  isAuthenticated = () => !!this.isValidToken(this.getAccessToken())
}

const authService = new AuthService();

export default authService;
