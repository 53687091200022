import TaxContext from './TaxContext';
import React, { useEffect, useReducer } from 'react';
import TaxReducer from './TaxReducer';
import taxService from '../../services/taxService';
import { FETCH_TAXES } from './TaxActions';

const TaxState = props => {
  const initialState = {
    taxes: []
  }

  useEffect(() => {
    taxService.fetchTaxes().then(taxes =>
      dispatch({
        type: FETCH_TAXES,
        payload: taxes
      })
      )
  }, [])

  const [state, dispatch] = useReducer(TaxReducer, initialState);

  return (
    <TaxContext.Provider value={{
      taxes: state.taxes
    }}>
      { props.children }
    </TaxContext.Provider>
  )

}

export default TaxState;
