import {
  ADD_IMAGES_TO_PICK_PRODUCT,
  CHANGE_FILTER,
  CHANGE_PRODUCTS,
  CLEAR_PRODUCTS,
  DELETE_PRODUCT,
  DISMISS_DELETE_DIALOG,
  LAUNCH_ERROR,
  PICK_PRODUCT,
  PRODUCT_CHANGE,
  SHOW_DELETE_DIALOG
} from './VoucherProductActions';

export default (state, action) => {
  switch (action.type) {
    case CHANGE_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        totalProducts: action.payload.totalProducts,
        loading: false,
        productPicked: null,
        productChanged: null,
        error: null
      }
    case CHANGE_FILTER:
      return {
        ...state,
        loading: true,
        filters: action.payload
      }
    case PICK_PRODUCT:
      return {
        ...state,
        productPicked: action.payload,
        productChanged: null,
        error: null
      }
    case ADD_IMAGES_TO_PICK_PRODUCT:
      return {
        ...state,
        productPicked: {
          ...state.productPicked,
          photoUrls: [...state.productPicked.photoUrls, ...action.payload]
        }
      }
    case PRODUCT_CHANGE:
      return {
        ...state,
        productChanged: action.payload,
        products: [...state.products].map(product => product.id === action.payload.id ? action.payload : product),
        error: null
      }
    case LAUNCH_ERROR:
      return {
        ...state,
        productChanged: null,
        error: action.payload
      }
    case CLEAR_PRODUCTS:
      return {
        ...state,
        productPicked: null,
        productChanged: null,
        error: null
      }
    case DELETE_PRODUCT:
      return {
        ...state,
        products: [...state.products].filter(product => product.id !== action.payload.id),
        productChanged: action.payload,
        totalProducts: state.totalProducts - 1
      }
    case SHOW_DELETE_DIALOG:
      return {
        ...state,
        productPicked: action.payload,
        showDelete: true
      }
    case DISMISS_DELETE_DIALOG:
      return {
        ...state,
        productPicked: null,
        showDelete: false,
        productChanged: null,
        error: null
      }
    default:
      return state;

  }
}
