import {
  FETCH_CLIENT_ANALYTICS,
  FETCH_COMMERCIAL_ANALYTICS,
  FETCH_FEE_ANALYTICS,
  FETCH_OPTION_ANALYTICS,
  FETCH_PRODUCT_ANALYTICS, FETCH_TOTAL_ANALYTICS, FETCH_TOTAL_RESUME_ANALYTICS, FETCH_USERS_ANALYTICS, INIT_ANALYTICS
} from './AnalyticsActions';

export default (state, action) => {
  switch (action.type) {
    case INIT_ANALYTICS:
      return {
        ...state,
        clientAnalytics: undefined,
        commercialAnalytics: undefined,
        feeAnalytics: undefined,
        productAnalytics: undefined,
        totalAnalytics: undefined,
        totalsResumeAnalytics: undefined,
        userAnalytics: undefined,
        optionAnalytics: undefined,
        temp: action.payload.temp,
        clientType: action.payload.type
      }
    case FETCH_CLIENT_ANALYTICS:
      return {
        ...state,
        clientAnalytics: action.payload
      }
    case FETCH_COMMERCIAL_ANALYTICS:
      return {
        ...state,
        commercialAnalytics: action.payload
      }
    case FETCH_FEE_ANALYTICS:
      return {
        ...state,
        feeAnalytics: action.payload
      }
    case FETCH_PRODUCT_ANALYTICS:
      return {
        ...state,
        productAnalytics: action.payload
      }
    case FETCH_TOTAL_ANALYTICS:
      return {
        ...state,
        totalAnalytics: action.payload
      }
    case FETCH_TOTAL_RESUME_ANALYTICS:
      return {
        ...state,
        totalsResumeAnalytics: action.payload
      }
    case FETCH_USERS_ANALYTICS:
      return {
        ...state,
        userAnalytics: action.payload
      }
      case FETCH_OPTION_ANALYTICS:
        return {
          ...state,
          optionAnalytics: action.payload
        }
    default:
      return state;
  }
}
