import { FETCH_PARTNER, UPDATE_SALES_MESSAGE } from './PartnerActions';

export default (state, action) => {
  switch (action.type) {
    case FETCH_PARTNER:
    case UPDATE_SALES_MESSAGE:
      return {
        ...state,
        partner: action.payload
      }
    default:
      return state;
  }
}
