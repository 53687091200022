import axios from 'axios';
import jwtDecode from 'jwt-decode';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

instance.interceptors.request.use((config) => {
  let token = localStorage.getItem('accessToken')
  if(token){
    config.headers.partner_id = jwtDecode(token).partnerId;
  }
  return config
}, (err) => {
  console.log(err)
  return Promise.reject(err)
})

export default instance;
