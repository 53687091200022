import VenueContext from './VenueContext';
import React, { useReducer } from 'react';
import VenueReducer from './VenueReducer';
import { API_RESULT, FETCH_VENUES, UPDATE_VENUE } from './VenueActions';
import venueService from '../../services/venueService';

const VenueState = props => {
  const initialState = {
    apiResult: null,
    venues: []
  }

  const [state, dispatch] = useReducer(VenueReducer, initialState);

  const fetchVenues = () => {
    venueService.fetchVenues().then(venues =>
      dispatch({
        type: FETCH_VENUES,
        payload: venues
      })
    )
  };

  const updateVenueSchedule = async (venueId, schedules) => {
    const updatedVenue = await venueService.updateVenueSchedule(venueId, schedules);

    if (updatedVenue) {
      dispatch({
        type: UPDATE_VENUE,
        payload: updatedVenue
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido actualizar el local'
        }
      });
    }
  };

  const changeVenueSales = async (venueId, salesMessageBlocked) => {
    const updatedVenue = await venueService.changeVenueSalesStatus(venueId, salesMessageBlocked);

    if (updatedVenue) {
      dispatch({
        type: UPDATE_VENUE,
        payload: updatedVenue
      });
    } else {
      dispatch({
        type: API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido actualizar el local'
        }
      });
    }
  };

  const sendMessage = (type, message) => {
    dispatch({
      type: API_RESULT,
      payload: {
        type,
        message
      }
    });
  };

  const clearApiResult = () => {
    dispatch({
      type: API_RESULT,
      payload: null
    })
  };

  return (
    <VenueContext.Provider value={{
      apiResult: state.apiResult,
      venues: state.venues,
      changeVenueSales,
      clearApiResult,
      fetchVenues,
      sendMessage,
      updateVenueSchedule
    }}>
      { props.children }
    </VenueContext.Provider>
  )

}

export default VenueState;
