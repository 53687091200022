import CommercialContext from './CommercialContext';
import { CLEAR_API_RESULT, COMMERCIALS_CHANGE, COMMERCIAL_CREATE, COMMERCIAL_UPDATE } from './CommercialActions';
import React, { useEffect, useReducer } from 'react';
import CommercialReducer from './CommercialReducer';
import commercialService from '../../services/commercialService';

const CommercialState = props => {
  const initialState = {
    commercials: [],
    apiResult: null
  }

  const [state, dispatch] = useReducer(CommercialReducer, initialState);

  useEffect(() => {
    findCommercials();
  }, []);

  const clearApiResult = () => {
    dispatch({
      type: CLEAR_API_RESULT
    });
  }

  const findCommercials = async (searchText) => {
    const commercials = await commercialService.getCommercials(searchText);

    dispatch({
      type: COMMERCIALS_CHANGE,
      payload: commercials
    })
  }

  const createCommercial = async (commercialForm) => {
    const createdCommercial = await commercialService.createCommercial(commercialForm);

    dispatch({
      type: COMMERCIAL_CREATE,
      payload: createdCommercial
    });
  }

  const updateCommercial = async (commercialId, commercialForm) => {
    const updatedCommercial = await commercialService.updateCommercial(commercialId, commercialForm);

    dispatch({
      type: COMMERCIAL_UPDATE,
      payload: updatedCommercial
    });
  }

  return (
    <CommercialContext.Provider value={{
      apiResult: state.apiResult,
      commercials: state.commercials,

      clearApiResult,
      createCommercial,
      findCommercials,
      updateCommercial
    }}>
      { props.children }
    </CommercialContext.Provider>
  )
}

export default CommercialState;
