import { FETCH_TAXES } from './TaxActions';

export default (state, action) => {
  switch (action.type) {
    case FETCH_TAXES:
      return {
        ...state,
        taxes: action.payload
      }
    default:
      return state;
  }
}
