import axios from '../utils/axiosDavo';

class NotificationService {

  fetchNotifications = () => axios.get(`/notifications`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  fetchShowInHomeNotifications = () => axios.get(`/notifications?showInHome=true`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  createNotification = notification =>
    axios.post(`/notifications`, notification)
      .then(response => response.data)

  updateNotification = (id, notification) =>
    axios.put(`/notifications/${id}`, notification)
      .then(response => response.data)

  sendPushNotification = notificationId =>
    axios.put(`/notifications/${notificationId}?type=push`)
      .then(response => response.data)

  getNotification = notificationId =>
    axios.get(`notifications/${notificationId}`)
      .then(response => response.data)

  deleteNotification = notificationId =>
    axios.delete(`notifications/${notificationId}`)
      .then(response => response.data)
}

const notificationService = new NotificationService();

export default notificationService;
