import axios from '../utils/axiosDavo';

class FamilyService {
  fetchFamilies = () => axios.get(`/families?show=all`)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      return []
    });

  createFamily = ({ description, erpId, hidden, name, photoUrl, priority, taxId, parentId, contentType, onlyImage }) => axios.post('families', {
    contentType,
    description,
    erpId,
    hidden,
    name,
    onlyImage,
    parentId,
    photoUrl,
    priority,
    taxId
  }).then(result => result.data).catch(error => console.error(error))

  findFamilyById = familiyId =>
    axios.get(`families/${familiyId}`)
      .then(result => result.data)
      .catch(error => console.error(error))

  updateFamily = (familyId, { description, erpId, hidden, onlyImage, name, photoUrl, priority, taxId, contentType, parentId }) => axios.put(`families/${familyId}`, {
    contentType,
    description,
    erpId,
    hidden,
    name,
    onlyImage,
    parentId,
    photoUrl,
    priority,
    taxId
  }).then(result => result.data).catch(error => console.error(error))

  deleteFamily = familyId =>
    axios.delete(`families/${familyId}`)
      .then(result => result.data)
      .catch(error => console.error(error))
}

const familyService = new FamilyService();

export default familyService;
