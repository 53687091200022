import React, { useEffect, useReducer } from 'react';
import ShippingMethodContext from './ShippingMethodContext';
import ShippingMethodReducer from './ShippingMethodReducer';
import {
  ADD_SHIPPING_METHOD,
  CHANGE_API_RESULT,
  FETCH_SHIPPING_METHODS,
  REMOVE_SHIPPING_METHOD, UPDATE_SHIPPING_METHOD
} from './ShippingMethodActions';
import shippingMethodService from '../../services/shippingMethodService';

const ShippingMethodState = props => {
  const initialState = {
    apiResult: null,
    shippingMethods: []
  }

  const [state, dispatch] = useReducer(ShippingMethodReducer, initialState);

  const clearApiResult = () => {
    dispatch({
      type: CHANGE_API_RESULT,
      payload: null
    })
  }

  useEffect(() => {
    fetchShippingMethods();
  }, [])

  const fetchShippingMethods = async () => {
    const shippingMethods = await shippingMethodService.fetchShippingMethods();

    dispatch({
      type: FETCH_SHIPPING_METHODS,
      payload: shippingMethods
    });
  }

  const deleteShippingMethod = async shippingMethodId => {
    const deletedShippingMethod = await shippingMethodService.deleteShippingMethod(shippingMethodId);

    if (deletedShippingMethod) {
      dispatch({
        type: REMOVE_SHIPPING_METHOD,
        payload: shippingMethodId
      })
    } else {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido eliminar el método de envío'
        }
      })
    }
  }

  const createShippingMethod = async (deliveryDays, erpId, freeFrom, limitHour, minTotalPrice, name, preparationDays, scope, type, value ) => {
    const newShippingMethod = await shippingMethodService.createShippingMethod(deliveryDays, erpId, freeFrom, limitHour, minTotalPrice, name, preparationDays, scope, type, value);

    if (!newShippingMethod) {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido crear un nuevo método de envío'
        }
      });
    } else {
      dispatch({
        type: ADD_SHIPPING_METHOD,
        payload: newShippingMethod
      })
    }
  }

  const updateShippingMethod = async (id, deliveryDays, erpId, freeFrom, fromAgency, limitHour, minTotalPrice, name, preparationDays, scope, value ) => {
    const updatedShippingMethod = await shippingMethodService.updateShippingMethod(id, deliveryDays, erpId, freeFrom, fromAgency, limitHour, minTotalPrice, name, preparationDays, scope, value);

    if (!updatedShippingMethod) {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido actualizar el método de envío'
        }
      });
    } else {
      dispatch({
        type: UPDATE_SHIPPING_METHOD,
        payload: updatedShippingMethod
      })
    }
  }

  return (
    <ShippingMethodContext.Provider value={{
      apiResult: state.apiResult,
      shippingMethods: state.shippingMethods,
      clearApiResult,
      createShippingMethod,
      deleteShippingMethod,
      fetchShippingMethods,
      updateShippingMethod
    }}>
      { props.children }
    </ShippingMethodContext.Provider>
  )
}

export default ShippingMethodState;
