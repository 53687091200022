import CouponContext from './CouponContext';
import React, { useReducer } from 'react';
import { ADD_COUPON, CHANGE_API_RESULT, FETCH_COUPONS, UPDATE_COUPON } from './CouponActions';
import couponService from '../../services/couponService';
import CouponReducer from './CouponReducer';

const CouponState = props => {
  const initialState = {
    apiResult: null,
    coupons: []
  }

  const clearApiResult = () => {
    dispatch({
      type: CHANGE_API_RESULT,
      payload: null
    });
  }

  const createCoupon = async couponForm => {
    const newCoupon =
      await couponService.createCoupon(couponForm);

    if (newCoupon) {
      dispatch({
        type: ADD_COUPON,
        payload: newCoupon
      })
    } else {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido crear el cupón'
        }
      })
    }
  }

  const fetchCoupons = () => {
    couponService.fetchCoupons().then(coupons =>
      dispatch({
        type: FETCH_COUPONS,
        payload: coupons
      })
    )
  }

  const updateCoupon = async (couponId, couponForm) => {
    const updatedCoupon = await couponService.updateCoupon(couponId, couponForm);

    if (updatedCoupon) {
      dispatch({
        type: UPDATE_COUPON,
        payload: updatedCoupon
      })
    } else {
      dispatch({
        type: CHANGE_API_RESULT,
        payload: {
          type: 'error',
          message: 'No se ha podido actualizar el cupón'
        }
      })
    }
  }

  const [state, dispatch] = useReducer(CouponReducer, initialState);

  return (
    <CouponContext.Provider value={{
      apiResult: state.apiResult,
      coupons: state.coupons,

      clearApiResult,
      createCoupon,
      fetchCoupons,
      updateCoupon
    }}>
      { props.children }
    </CouponContext.Provider>
  )

}

export default CouponState;
