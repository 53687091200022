import axios from '../utils/axiosDavo';

class PriceService {
  constructor() {
    this._limit = 20;
  }

  fetchPrices = (page, query, limit = this._limit) => axios.get(`/prices?search_text=${query ? query : ""}&offset=${page * limit}&limit=${limit}`)
    .then(response => response)
    .catch(error => {
      console.error(error)
      return []
    });

  findPriceById = priceId => axios.get(`/prices/${priceId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  fetchPricesByFee = (feeId, page, query, limit = this._limit) => axios.get(`/fees/${feeId}/prices?search_text=${query ? query : ""}&offset=${page * limit}&limit=${limit}`)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return [];
    })

  deletePrice = priceId =>
    axios.delete(`/prices/${priceId}`)
      .then(response => response.data)
      .catch(error => console.error(error))

  findByProductId = productId =>
    axios.get(`/products/${productId}/prices`)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return [];
      });

  findByClientId = clientId =>
    axios.get(`/clients/${clientId}/prices?type=blocked`)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return [];
      });

  findById = priceId =>
    axios.get(`/prices/${priceId}`)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return [];
      })

  deleteByProductId = productId =>
    axios.delete(`/products/${productId}/prices`)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return [];
      })

  upsertPriceByFee = (feeId, productId, compareTo, value) =>
    axios.put(`/fees/${feeId}/products/${productId}/prices`, {
      compareTo,
      value
    }).then(response => response.data).catch(error => console.error(error))

  upsertPriceByClientId = (clientId, productId, value) =>
    axios.put(`/clients/${clientId}/products/${productId}/prices`, {
      value
    }).then(response => response.data).catch(error => console.error(error))

  importPrice = ({client, fee, product, price, sale}) => {
    const importPrice = {
      clientErpId: client,
      feeErpId: fee,
      productErpId: product,
      sale,
      value: price
    }

    return axios.put(`/prices?from=csv`, importPrice);
  }
}

const priceService = new PriceService();

export default priceService;
