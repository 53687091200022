import axios from '../utils/axiosDavo';

class LogUserService {

  fetchLogs = () => axios.get(`/log_users`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

    createLog = async (userId) => {
      const ipResponse = await axios.get("https://api.ipify.org/?format=json");

      axios.post(`/log_users/`, {ip: ipResponse.data ?? 'unknown', userId})
        .then(result => result.data)
        .catch(error => {
          console.error(error);
          return null;
        });
    }
      
}

const logUserService = new LogUserService();

export default logUserService;
