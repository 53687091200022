import {
  ADD_HOME_SECTION,
  CLEAR_API_RESPONSE,
  DELETE_HOME_SECTION,
  HOME_SECTIONS_CHANGE,
  SEND_API_RESPONSE,
  UPDATE_HOME_SECTION
} from './HomeSectionActions';
import React, { useReducer } from 'react';
import homeSectionService from '../../services/homeSectionService';
import HomeSectionReducer from './HomeSectionReducer';
import HomeSectionContext from './HomeSectionContext';

const HomeSectionState = props => {
  const initialState = {
    homeSections: [],
    apiResponse: null
  }

  const [state, dispatch] = useReducer(HomeSectionReducer, initialState);

  const fetchHomeSections = async () => {
    const homeSections = await homeSectionService.fetchHomeSections();

    dispatch({
      type: HOME_SECTIONS_CHANGE,
      payload: homeSections
    })
  }

  const createHomeSection = async homeSection => {
    const newHomeSection =
      await homeSectionService.createHomeSection(homeSection);

    if (newHomeSection) {
      dispatch({
        type: ADD_HOME_SECTION,
        payload: newHomeSection
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido crear la sección'
        }
      })
    }
  }

  const clearApiResponse = () => {
    dispatch({
      type: CLEAR_API_RESPONSE
    })
  }

  const updateHomeSection = async (id, homeSection) => {
    const updatedHomeSection =
      await homeSectionService.updateHomeSection(id, homeSection);

    if (updatedHomeSection) {
      dispatch({
        type: UPDATE_HOME_SECTION,
        payload: updatedHomeSection
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido actualizar la sección'
        }
      })
    }
  }

  const deleteHomeSection = async homeSectionId => {
    const deletedHomeSection =
      await homeSectionService.deleteHomeSection(homeSectionId)

    if (deletedHomeSection) {
      dispatch({
        type: DELETE_HOME_SECTION,
        payload: deletedHomeSection
      })
    } else {
      dispatch({
        type: SEND_API_RESPONSE,
        payload: {
          type: 'error',
          message: 'No se ha podido eliminar la sección'
        }
      })
    }
  }

  return (
    <HomeSectionContext.Provider value={{
      apiResponse: state.apiResponse,
      homeSections: state.homeSections,
      clearApiResponse,
      createHomeSection,
      deleteHomeSection,
      fetchHomeSections,
      updateHomeSection
    }}>
      { props.children }
    </HomeSectionContext.Provider>
  )
}

export default HomeSectionState;
