import {
  CLEAR_API_RESULT,
  COMMERCIALS_CHANGE, COMMERCIAL_CREATE, COMMERCIAL_UPDATE
} from './CommercialActions';

export default (state, action) => {
  switch (action.type) {
    case CLEAR_API_RESULT:
      return {
        ...state,
        apiResult: null
      }
    case COMMERCIAL_CREATE:
      return {
        ...state,
        commercials: [...state.commercials, action.payload],
        apiResult: {
          action: 'post',
          type: 'success',
          message: 'Comercial creado correctamente'
        }
      }
    case COMMERCIAL_UPDATE:
      return {
        ...state,
        commercials: state.commercials.map(commercial => commercial.id === action.payload.id ? action.payload : commercial),
        apiResult: {
          action: 'put',
          type: 'success',
          message: 'Comercial actualizado correctamente'
        }
      }
    case COMMERCIALS_CHANGE:
      return {
        ...state,
        commercials: action.payload
      };
      
    default:
      return state;
  }
}
