export const CHANGE_PRODUCTS = 'CHANGE_PRODUCTS';
export const CHANGE_FEATURES = 'CHANGE_FEATURES';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const EDIT_FEATURE = 'EDIT_FEATURE';
export const PICK_PRODUCT = 'PICK_PRODUCT';
export const ADD_IMAGES_TO_PICK_PRODUCT = 'ADD_IMAGES_TO_PICK_PRODUCT'
export const PRODUCT_CHANGE = 'PRODUCT_CHANGE'
export const LAUNCH_ERROR = 'LAUNCH_ERROR'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const SHOW_DELETE_DIALOG = 'SHOW_DELETE_DIALOG'
export const DISMISS_DELETE_DIALOG = 'DISMISS_DELETE_DIALOG'
export const IMPORT_PRODUCTS_RESPONSE = 'IMPORT_PRODUCTS_RESPONSE'
export const EXPORT_PRODUCTS_RESPONSE = 'EXPORT_PRODUCTS_RESPONSE'
export const CLEAR_IMPORT_EXPORT_RESPONSE = 'CLEAR_IMPORT_RESPONSE'
export const INIT_IMPORTS = 'INIT_IMPORTS';
