export const ADD_CLIENT = 'ADD_CLIENT';
export const CLIENTS_CHANGE = 'CLIENTS_CHANGE'
export const PICK_CLIENT = 'PICK_CLIENT'
export const UPSERT_CLIENT_PRICE = 'UPSERT_CLIENT_PRICE'
export const DELETE_CLIENT_PRICE = 'DELETE_CLIENT_PRICE'
export const IMPORT_CLIENTS_RESPONSE = 'IMPORT_CLIENTS_RESPONSE'
export const EXPORT_CLIENTS_RESPONSE = 'EXPORT_CLIENTS_RESPONSE'
export const CLEAR_IMPORT_EXPORT_CLIENTS_RESPONSE = 'CLEAR_IMPORT_EXPORT_CLIENTS_RESPONSE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const API_RESULT = 'API_RESULT'
export const CLEAN_API_RESULT = 'CLEAN_API_RESULT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const INIT_IMPORTS = 'INIT_IMPORTS';
