import axios from '../utils/axiosDavo';

class CouponService {

  createCoupon = couponForm =>
    axios.post(`/coupons`, couponForm)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return null;
      });

  getCouponById = couponId => axios.get(`/coupons/${couponId}`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return null;
    });

  fetchCoupons = () => axios.get(`/coupons`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return []
    });

  updateCoupon = (couponId, couponForm) =>
    axios.put(`/coupons/${couponId}`, couponForm)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return null;
      });
}

const couponService = new CouponService();

export default couponService;
